import React, { useRef, useState, useEffect } from 'react';
import { BsX, BsSearch } from "react-icons/bs";
import { CiMicrophoneOn } from "react-icons/ci";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

const SearchBar = ({ handleSearch, setSearchQuery, searchQuery, showNotification, showMic = false }) => {
  const inputRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [isVoiceSearch, setIsVoiceSearch] = useState(false); 
  const [showMicState, setShowMic] = useState(showMic); 

  useEffect(() => {
    setShowMic(showMic);
  }, [showMic]);

  useEffect(() => {
    if (isVoiceSearch && searchQuery.length > 0) {
      handleSearch();  
      setIsVoiceSearch(false);  
    }
  }, [searchQuery, isVoiceSearch, handleSearch]); 

  useEffect(() => {
    return () => {
      if (isListening) {
        setIsListening(false);
      }
    };
  }, [isListening]);

  const wordsToReplace = {
    "cookies": "كوكيز",
    "cake": "كيك",
    "coffee": "قهوة",
    "Phone":  "فون"
    // يمكن إضافة المزيد من الكلمات هنا
  };

  const playBeepSound = () => {
    const audio = new Audio('/sounds/mic-on.mp3');
    audio.play().catch(error => {
      console.error('خطأ في تشغيل الصوت:', error);
    });
  };

  const playStopSound = () => {
    const audio = new Audio('/sounds/mic-off.mp3');
    audio.play().catch(error => {
      console.error('خطأ في تشغيل الصوت:', error);
    });
  };

  const handleMicClick = () => {
    if (isListening) {
      setIsListening(false);
      playStopSound(); 
      if (window.currentRecognizer && !window.currentRecognizer.isDisposed) {
        window.currentRecognizer.close();
        window.currentRecognizer = null;
        console.log('تم إيقاف التسجيل يدوياً');
      }
      if (window.stream) {
        window.stream.getTracks().forEach(track => track.stop());
      }
      if (window.noSpeechTimeoutId) {
        clearTimeout(window.noSpeechTimeoutId);
      }
      return;
    }

    const speechConfig = sdk.SpeechConfig.fromSubscription("DuDxHXPlpaWR5FYnzKX8WsA8K3zE98wXyVR5r92464aDtJAWwjxNJQQJ99ALACYeBjFXJ3w3AAAAACOGAuU9", "eastus");
    speechConfig.speechRecognitionLanguage = "ar-SA";

    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
    window.currentRecognizer = recognizer;

    setIsListening(true);
    console.log('بدء التسجيل...');
    playBeepSound(); 

    // Add timeout for no speech detection
    window.noSpeechTimeoutId = setTimeout(() => {
      console.log('لم يتم اكتشاف صوت، جاري الإيقاف...');
      setIsListening(false);
      if (recognizer && !recognizer.isDisposed) {
        recognizer.close();
      }
      if (window.stream) {
        window.stream.getTracks().forEach(track => track.stop());
      }
    }, 5000); // 5 seconds timeout

    recognizer.recognizeOnceAsync(result => {
      if (window.noSpeechTimeoutId) {
        clearTimeout(window.noSpeechTimeoutId);
      }
      console.log('تم استلام نتيجة التعرف على الصوت');
      if (result.reason === sdk.ResultReason.RecognizedSpeech) {
        let cleanText = result.text
          .replace(/[.,!?؟]/g, '') 
          .replace(/\s+/g, ' ') 
          .replace(/،/g, '') 
          .replace(/\s*,\s*/g, ' ') 
          .trim(); 

        for (let [english, arabic] of Object.entries(wordsToReplace)) {
          cleanText = cleanText.replace(new RegExp(english, 'gi'), arabic);
        }
        setSearchQuery(cleanText);
        setIsVoiceSearch(true);
        handleSearch();
        console.log("تم التعرف على النص عبر الصوت:", cleanText);
      } else {
        console.error('خطأ في التعرف على الصوت:', result.errorDetails || 'حدث خطأ غير معروف');
        showNotification('حدث خطأ أثناء التعرف على الصوت. حاول مرة أخرى.');
      }
      setIsListening(false);
      if (!recognizer.isDisposed) {
        recognizer.close();
      }
      if (window.stream) {
        window.stream.getTracks().forEach(track => track.stop());
      }
    });

    recognizer.onstart = () => {
      console.log('بدأ التسجيل');
      setIsListening(true);
    };

    recognizer.onend = () => {
      console.log('انتهى التسجيل');
      setIsListening(false);
      if (window.stream) {
        window.stream.getTracks().forEach(track => {
          track.stop();
          console.log('تم إيقاف مسار الصوت');
        });
      }
    };

    recognizer.onerror = (event) => {
      console.error('خطأ في التعرف على الصوت:', event.error);
      setIsListening(false);
      if (!recognizer.isDisposed) {
        recognizer.close();
        console.log('تم إغلاق المتعرف على الصوت بسبب خطأ');
      }
      if (window.stream) {
        window.stream.getTracks().forEach(track => track.stop());
      }
    };

    recognizer.canceled = (s, e) => {
      console.log('تم إلغاء التسجيل');
      setIsListening(false);
    };

    recognizer.sessionStopped = (s, e) => {
      console.log('توقفت جلسة التسجيل');
      setIsListening(false);
    };

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        window.stream = stream;
        stream.getAudioTracks()[0].onended = () => {
          console.log('انتهى إدخال الصوت');
          setIsListening(false);
          if (!recognizer.isDisposed) {
            recognizer.close();
            console.log('تم إغلاق المتعرف على الصوت بعد انتهاء الصوت');
          }
        };
      })
      .catch(err => {
        console.error('خطأ في الوصول إلى الميكروفون:', err);
        setIsListening(false);
      });
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    setIsVoiceSearch(false); 
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  const micIconStyle = isListening ? { color: 'red' } : {};

  return (
    <form
      className="px-4 pt-2 flex gap-2 relative dark:bg-gray-700"
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <input
        ref={inputRef}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        value={searchQuery}
        autoComplete="off"
        type="text"
        enterKeyHint="search"
        placeholder="البحث . . ."
        name="search"
        className={`outline-none border border-gray-200 focus:border-primaryGreen-500 transition duration py-1.5 ${showMicState ? 'px-8 pl-8' : 'px-8 pr-4'} flex-grow rounded-full bg-transparent dark:placeholder:text-white dark:text-white peer`}
      />
      {showMicState && ( 
        <button
          onClick={handleMicClick}
          style={micIconStyle}
          className="absolute right-[1.5rem] top-[58%] transform -translate-y-1/2 dark:text-white text-gray-500 peer-focus:text-primaryGreen-500"
          title="استخدام الميكروفون"
        >
          <CiMicrophoneOn style={{ height: '1.5em', width: '1.5em' }} />
        </button>
      )}
      {searchQuery.length > 0 ? (
        <button
          className="w-10 h-10 flex items-center justify-center absolute left-4 dark:text-white text-gray-500 peer-focus:text-primaryGreen-500"
          onClick={() => setSearchQuery('')}
          type='button'
          title="إنهاء البحث"
        >
          <BsX className="text-2xl" />
        </button>
      ) : (
        <button
          className="w-10 h-10 flex items-center justify-center rounded-md absolute left-4 peer-focus:text-primaryGreen-500 dark:text-white text-gray-500 focus:outline-none outline-none border-none transition-none active:outline-none"
          title="البحث"
          onClick={(e) => {
            e.preventDefault();
            inputRef.current?.focus();
          }}
        >
          <BsSearch className="" />
        </button>
      )}
    </form>
  );
};

export default SearchBar;
