import React, { useState } from 'react';
import Map from './Map'; // Assuming you have a Map component
import { IoHomeOutline, IoBriefcaseOutline, IoEllipsisHorizontalOutline } from 'react-icons/io5';

export default function Addresses() {
  const [showMap, setShowMap] = useState(false);
  const [addressConfirmed, setAddressConfirmed] = useState(false);
  const [primaryAddressId, setPrimaryAddressId] = useState(null);
  const [addresses, setAddresses] = useState([]);

  const handleAddNewAddress = () => {
    setShowMap(true);
  };

  const handleConfirmAddress = () => {
    setShowMap(false);
    setAddressConfirmed(true);
  };

  const handleSetPrimaryAddress = (id) => {
    setPrimaryAddressId(id);
  };

  const handleSaveAddress = () => {
    const newAddress = {
      id: Date.now(),
      details: '3841 شارع الوليد بن عبد الملك، طويق، الرياض، 14914، السعودية',
      type: 'البيت'
    };
    setAddresses([...addresses, newAddress]);
    setAddressConfirmed(false);
  };

  return (
    <div className="bg-[#004d3f] min-h-screen flex flex-col items-center justify-center">
      {addresses.length > 0 && (
        <div className="bg-[#005544] text-[#f0e9d2] p-6 rounded-lg shadow-md w-11/12 max-w-md mb-4">
          <h2 className="text-2xl font-bold mb-4">العناوين المحفوظة</h2>
          <div className="flex flex-col gap-4">
            {addresses.map(address => (
              <div key={address.id} className="bg-[#005544] text-[#f0e9d2] p-4 rounded-lg shadow-md flex justify-between items-center">
                <div>
                  <h3 className="text-lg font-bold mb-2">{address.type}</h3>
                  <p>{address.details}</p>
                </div>
                {primaryAddressId === address.id ? (
                  <span className="bg-[#f0e9d2] text-[#004d3f] py-1 px-2 rounded-full">الأساسية</span>
                ) : (
                  <button onClick={() => handleSetPrimaryAddress(address.id)} className="bg-[#f0e9d2] text-[#004d3f] py-1 px-2 rounded-full ml-2">
                    تعيين
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {!showMap && !addressConfirmed && (
        <div className="bg-[#005544] text-[#f0e9d2] p-6 rounded-lg shadow-md w-11/12 max-w-md">
          <h2 className="text-2xl font-bold mb-4">العنوان</h2>
          <p className="mb-4">لم يتم إضافة أي عنوان بعد</p>
          <button 
            onClick={handleAddNewAddress}
            className="w-full bg-[#f0e9d2] text-[#004d3f] py-2 rounded-full hover:bg-[#e0d9c2]">
            + إضافة عنوان جديد
          </button>
        </div>
      )}
      {showMap && (
        <div className="w-full h-2/3 bg-white p-4">
          <Map />
          <button 
            onClick={handleConfirmAddress}
            className="mt-4 w-full bg-[#f0e9d2] text-[#004d3f] py-2 rounded-full hover:bg-[#e0d9c2]">
            تأكيد
          </button>
        </div>
      )}
      {addressConfirmed && (
        <div className="bg-[#005544] text-[#f0e9d2] p-6 rounded-lg shadow-md w-11/12 max-w-md">
          <h2 className="text-2xl font-bold mb-4">موقع التوصيل</h2>
          <p className="mb-4">3841 شارع الوليد بن عبد الملك، طويق، الرياض، 14914، السعودية</p>
          <input type="text" placeholder="المبنى، الدور، رقم الشقة" className="w-full p-2 mb-2 rounded bg-[#f0e9d2] text-[#004d3f]" />
          <input type="text" placeholder="إضافة تعليمات" className="w-full p-2 mb-2 rounded bg-[#f0e9d2] text-[#004d3f]" />
          <div className="flex justify-around mt-4">
            <button className="bg-[#f0e9d2] text-[#004d3f] py-1 px-4 rounded-full hover:bg-[#e0d9c2] flex items-center gap-2">
              <IoHomeOutline /> البيت
            </button>
            <button className="bg-[#f0e9d2] text-[#004d3f] py-1 px-4 rounded-full hover:bg-[#e0d9c2] flex items-center gap-2">
              <IoBriefcaseOutline /> العمل
            </button>
            <button className="bg-[#f0e9d2] text-[#004d3f] py-1 px-4 rounded-full hover:bg-[#e0d9c2] flex items-center gap-2">
              <IoEllipsisHorizontalOutline /> أخرى
            </button>
          </div>
          <button onClick={handleSaveAddress} className="w-full bg-[#f0e9d2] text-[#004d3f] py-2 rounded-full hover:bg-[#e0d9c2] mt-4">
            حفظ العنوان
          </button>
        </div>
      )}
    </div>
  );
}
