import { useLoadScript } from '@react-google-maps/api';
import { memo } from 'react';

const libraries = ['places'];

const GoogleMapsProvider = memo(({ children }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: "ar",
    region: "SA",
    version: "weekly",
    channel: "beta"
  });

  if (loadError) {
    return null; // تجنب إظهار أي رسائل خطأ للمستخدم
  }

  if (!isLoaded) {
    return null; // تجنب إظهار شاشة التحميل
  }

  return children;
});

GoogleMapsProvider.displayName = 'GoogleMapsProvider';

export default GoogleMapsProvider;
