/*global google*/

import { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { IoIosArrowBack } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { useForm } from 'react-hook-form'
import { BiTargetLock } from 'react-icons/bi'
import { restaurants, isBranchOpen } from '../hooks/branchesManager'
import { useGlobalContext } from '../context'
import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import marker from '../img/marker2.svg'
import marker2 from '../img/marker.svg'
import point from '../img/point.png'

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const ReciveFromStore = () => {
  const [resLatLng, setResLatLng] = useState(null) // بدون تحديد فرع افتراضي
  const [userLatLng, setUserLatLng] = useState(null)
  const [value, setValue] = useState(null)
  const [storeName, setStoreName] = useState('')
  const [step, setStep] = useState(1)
  const activeRef = useRef(null)
  const mapRef = useRef(null)
  const navigate = useNavigate()

  // compare distance between restauran and user
  
  const toRad = (Value) => {
    return (Value * Math.PI) / 180;
  };
  
  const calcCrow = useCallback((lat1, lon1, lat2, lon2) => {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var latitude1 = toRad(lat1);
    var latitude2 = toRad(lat2);
  
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(latitude1) *
        Math.cos(latitude2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }, []) // تأكد من تضمين التبعيات الصحيحة إذا كانت هناك أي
  
  // Find the distnce between restaurant and user
  const storeUserDistance = useCallback((storeLat, storeLng) => {
    if (!userLatLng) return 0
    return calcCrow(userLatLng.lat, userLatLng.lng, storeLat, storeLng)
  }, [userLatLng, calcCrow])

  const formatTime = (time) => {
    if (typeof time === 'string') {
      const [hours, minutes] = time.split(':')
      const hour = parseInt(hours)
      const ampm = hour >= 12 ? 'م' : 'ص'
      const formattedHour = hour % 12 || 12
      return `${formattedHour}:${minutes} ${ampm}`
    }
    return time
  }

  // Get User location and handle branch selection
  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude } = location.coords
          setUserLatLng({ lat: latitude, lng: longitude })
          
          // حساب المسافة لكل الفروع المفتوحة فقط
          const openBranches = restaurants.filter(isBranchOpen)
          
          // اختيار الفرع المناسب
          if (openBranches.length > 0) {
            const distances = openBranches.map((res) => ({
              ...res,
              distance: calcCrow(latitude, longitude, res.coords.lat, res.coords.lng),
            }))

            // اختيار أقرب فرع مفتوح
            const nearest = distances.reduce((prev, curr) =>
              prev.distance < curr.distance ? prev : curr
            )

            setValue(restaurants.findIndex((res) => res.id === nearest.id))
            setStoreName(nearest.name)
            setResLatLng(nearest.coords)

            // تحديث الخريطة
            if (mapRef.current) {
              mapRef.current.panTo(nearest.coords)
            }
          } else {
            // إذا كانت كل الفروع مغلقة، نختار أقرب فرع مغلق
            const closedBranchesWithDistance = restaurants.map(branch => ({
              ...branch,
              distance: calcCrow(latitude, longitude, branch.coords.lat, branch.coords.lng)
            }))

            const nearestClosed = closedBranchesWithDistance.reduce((prev, curr) => 
              prev.distance < curr.distance ? prev : curr
            )

            setValue(restaurants.findIndex((res) => res.id === nearestClosed.id))
            setStoreName(nearestClosed.name)
            setResLatLng(nearestClosed.coords)
            if (mapRef.current) {
              mapRef.current.panTo(nearestClosed.coords)
            }
            toast.warning('جميع الفروع مغلقة حالياً')
          }
        },
        (error) => {
          toast.error('لم نتمكن من تحديد موقعك')
          toast.info('يمكنك تفعيل خدمة الموقع لمعرفة أقرب فرع لك')
          handleNoLocationBranchSelection()
        }
      )
    } else {
      toast.error('لم نتمكن من تحديد موقعك')
      toast.info('يمكنك تفعيل خدمة الموقع لمعرفة أقرب فرع لك')
      handleNoLocationBranchSelection()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNoLocationBranchSelection = useCallback(() => {
    const openBranch = restaurants.find(isBranchOpen)
    if (openBranch) {
      setValue(restaurants.findIndex((res) => res.id === openBranch.id))
      setStoreName(openBranch.name)
      setResLatLng(openBranch.coords)
      if (mapRef.current) {
        mapRef.current.panTo(openBranch.coords)
      }
    } else {
      const defaultBranch = restaurants[0]
      setValue(0)
      setStoreName(defaultBranch.name)
      setResLatLng(defaultBranch.coords)
      if (mapRef.current) {
        mapRef.current.panTo(defaultBranch.coords)
      }
      toast.warning('جميع الفروع مغلقة حالياً')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // MESSAGE
  const { cartData, setCartData, percentage } = useGlobalContext()
  const total = cartData
    .reduce((acc, item) => acc + item.price, 0)
    .toLocaleString('en-US')
  const date = new Date(Date.now())
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'مساءً' : 'صباحاً'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const time = `${hours}:${minutes} ${ampm}`

  const orderTotal = cartData.reduce((acc, item) => acc + item.price, 0)
  const discount = ((percentage / 100) * orderTotal).toLocaleString('en-US')
  const totaAfterDiscount = (
    orderTotal -
    (percentage / 100) * orderTotal
  ).toLocaleString('en-US')

  // تحديد التاريخ والوقت الحالي
  const getCurrentDateTime = () => {
    // استخدام توقيت الرياض
    const now = new Date();
    const riyadhDate = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }));
    
    // تنسيق التاريخ YYYY-MM-DD
    const year = riyadhDate.getFullYear();
    const month = String(riyadhDate.getMonth() + 1).padStart(2, '0');
    const day = String(riyadhDate.getDate()).padStart(2, '0');
    const currentDate = `${year}-${month}-${day}`;

    // تنسيق الوقت HH:mm
    const currentHours = String(riyadhDate.getHours()).padStart(2, '0');
    const currentMinutes = String(riyadhDate.getMinutes()).padStart(2, '0');
    const currentTime = `${currentHours}:${currentMinutes}`;

    return { currentDate, currentTime };
  };

  const { currentDate, currentTime } = getCurrentDateTime();

  // التحقق من أن التاريخ ليس في الماضي
  const isDateValid = (dateStr) => {
    const selectedDate = new Date(dateStr);
    const today = new Date(getCurrentDateTime().currentDate);
    today.setHours(0, 0, 0, 0);
    return selectedDate >= today;
  };

  // ترتيب الفروع حسب المسافة والحالة (مفتوح/مغلق)
  const sortedRestaurants = useMemo(() => {
    // التحقق من وجود إحداثيات المستخدم
    if (!userLatLng) {
      // إذا لم تكن هناك إحداثيات، نرتب حسب الاسم فقط
      return [...restaurants].sort((a, b) => a.name.localeCompare(b.name));
    }
    
    // إذا كانت هناك إحداثيات، نرتب حسب المسافة والحالة
    return [...restaurants].sort((a, b) => {
      const aOpen = isBranchOpen(a);
      const bOpen = isBranchOpen(b);
      
      // أولوية للفروع المفتوحة
      if (aOpen !== bOpen) {
        return aOpen ? -1 : 1;
      }
      
      // إذا كان كلاهما مفتوح أو مغلق، نرتب حسب المسافة
      const distanceA = storeUserDistance(a.coords.lat, a.coords.lng);
      const distanceB = storeUserDistance(b.coords.lat, b.coords.lng);
      return distanceA - distanceB;
    });
    
  }, [userLatLng, storeUserDistance]) // restaurants هو قيمة ثابتة من الملف

  // تحديث الفهرس عندما يتغير الترتيب
  useEffect(() => {
    if (storeName && sortedRestaurants.length > 0) {
      const newIndex = sortedRestaurants.findIndex(r => r.name === storeName);
      if (newIndex !== -1) {
        setValue(newIndex);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedRestaurants, storeName])

  // التحقق من أن الوقت ضمن ساعات العمل
  const isTimeValid = (timeStr) => {
    if (!timeStr || !sortedRestaurants || !sortedRestaurants[value]) return true;

    const selectedBranch = sortedRestaurants[value];
    const selectedDate = new Date(watch('date'));
    const localDate = new Date(selectedDate.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }));
    const dayName = days[localDate.getDay()];
    
    const workingHours = selectedBranch.workingHours[dayName];
    if (!workingHours) return false; // الفرع مغلق في هذا اليوم
    if (selectedBranch.open24h || workingHours.is24h) return true; // الفرع يعمل 24 ساعة

    const [hours, minutes] = timeStr.split(':').map(Number);
    const [openHours, openMinutes] = workingHours.open.split(':').map(Number);
    const [closeHours, closeMinutes] = workingHours.close.split(':').map(Number);

    const selectedTime = hours * 60 + minutes;
    const openTime = openHours * 60 + openMinutes;
    const closeTime = closeHours * 60 + closeMinutes;

    // إذا كان وقت الإغلاق بعد منتصف الليل
    if (closeTime < openTime) {
      return selectedTime >= openTime || selectedTime <= closeTime;
    }

    return selectedTime >= openTime && selectedTime <= closeTime;
  };

  // Form for order details
  const {
    register,
    handleSubmit,
    watch,
    setValue: setFormValue,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: currentDate,
      time: currentTime,
    },
  })

  // مراقبة التاريخ والوقت للتحقق الفوري
  const selectedDate = watch('date');
  const selectedTime = watch('time');
  const isCurrentDateValid = isDateValid(selectedDate);
  const isCurrentTimeValid = isTimeValid(selectedTime);

  // تحديث التاريخ والوقت عند تحميل المكون
  useEffect(() => {
    const updateDateTime = () => {
      const { currentDate, currentTime } = getCurrentDateTime();
      setFormValue('date', currentDate);
      setFormValue('time', currentTime);
    };

    // تحديث التاريخ والوقت عند تحميل المكون
    updateDateTime();
  }, [setFormValue]);

  // Form for map search
  const {
    
    handleSubmit: handleSubmitMap,
    watch: watchMap,
    setValue: setMapValue,
    reset: resetMap,
  } = useForm()

  const handleFormSubmit = (data) => {
    const selectedBranch = sortedRestaurants[value]
    
    // التحقق من التاريخ
    const selectedDate = new Date(data.date);
    const today = new Date(getCurrentDateTime().currentDate);
    today.setHours(0, 0, 0, 0);

    if (selectedDate < today) {
      const formattedDate = today.toLocaleDateString('ar-SA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).replace(/\//g, '-');
      
      toast.error(
        <div style={{ direction: 'rtl', textAlign: 'center' }}>
          عذراً، لا يمكن اختيار تاريخ قبل {formattedDate}
        </div>,
        {
          toastId: 'date-error',
          autoClose: 3000,
          position: "top-center",
          hideProgressBar: false
        }
      );
      return;
    }
    
    // التحقق من وقت العمل
    const localDate = new Date(selectedDate.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }));
    const dayName = days[localDate.getDay()];
    
    const workingHours = selectedBranch.workingHours[dayName];
    if (!workingHours) {
      toast.error(`عذراً، الفرع مغلق في هذا اليوم`);
      return;
    }

    if (selectedBranch.open24h || workingHours.is24h) {
    } else {
      const [inputHours, inputMinutes] = data.time.split(':').map(Number);
      const inputTimeInMinutes = inputHours * 60 + inputMinutes;

      const [openHours, openMinutes] = workingHours.open.split(':').map(Number);
      const [closeHours, closeMinutes] = workingHours.close.split(':').map(Number);
      const openTimeInMinutes = openHours * 60 + openMinutes;
      const closeTimeInMinutes = closeHours * 60 + closeMinutes;

      let isTimeValid;
      if (closeTimeInMinutes < openTimeInMinutes) {
        isTimeValid = inputTimeInMinutes >= openTimeInMinutes || inputTimeInMinutes <= closeTimeInMinutes;
      } else {
        isTimeValid = inputTimeInMinutes >= openTimeInMinutes && inputTimeInMinutes <= closeTimeInMinutes;
      }

      if (!isTimeValid) {
        const { open, close } = workingHours;
        toast.error(
          <div>
            عذراً، الفرع مغلق في هذا الوقت
            <br />
            ساعات العمل من {formatTime(open)} إلى {formatTime(close)}
          </div>,
          {
            toastId: 'time-error',
            autoClose: 3000,
            position: "top-center",
            hideProgressBar: false
          }
        );
        return;
      }
    }

    // Get the last order number from localStorage
    const orders = JSON.parse(localStorage.getItem('orders') || '[]')
    const lastOrderNumber = orders.length > 0 ? parseInt(orders[0].orderId) || 0 : 0
    const newOrderNumber = lastOrderNumber + 1

    const message = `*نوع الطلب* : إستلام من الفرع%0A*رقم الطلب* : 0000${newOrderNumber}%0A*اسم الفرع* : ${selectedBranch.name}%0A*تاريخ الاستلام* : ${data.date}%0A*وقت الاستلام* : ${data.time}%0A---------------------------%0A${cartData
      .map((item) => {
        let size = ''
        let extras = ''
        let edits = ''
        if (item.size) {
          switch (item.size.name) {
            case 'small':
              size = 'صغير'
              break
            case 'medium':
              size = 'وسط'
              break
            case 'large':
              size = 'كبير'
              break
            default:
              size = ''
          }
        }      
    
        if (item.extra && item.extra.length > 0) {
          extras = `%0A*الإضافات* :%0A    • ${item.extra.map(e => e.name).join('%0A    • ')}`;
        }
        
        if (item.edits && item.edits.length > 0) {
          edits = `%0A*التعديلات* :%0A    • ${item.edits.join('%0A    • ')}`;
        }

        return `*الصنف* : ${item.name}%0A${
          size ? `*الحجم* : ${size}%0A` : ''
        }*الكمية* : ${item.quantity || 1}%0A*السعر* : ${
          item.price
        } ريال${extras}${edits}`;
      })
      .join('%0A---------------------------%0A')}%0A---------------------------%0A%0A*مبلغ الطلب* : ${total} ريال%0A*المجموع* : ${orderTotal.toLocaleString(
    'en-US'
  )} ريال${
    percentage
      ? `%0A*الخصم* : ${discount} ريال%0A*المجموع بعد الخصم* : ${totaAfterDiscount} ريال`
      : ''
  }%0A*وقت الطلب* : ${time}`;

    try {
      // Save order to localStorage
      const newOrder = {
        orderId: newOrderNumber,
        date: new Date().toISOString(),
        pickupDate: data.date,
        pickupTime: data.time,
        items: cartData.map(item => ({
          name: item.name,
          price: item.price,
          quantity: item.quantity || 1,
          image: item.img,
          size: item.size ? (
            item.size.name === 'small' ? 'صغير' : 
            item.size.name === 'medium' ? 'وسط' : 
            item.size.name === 'large' ? 'كبير' : ''
          ) : 'وسط',
          additions: item.extra ? item.extra.map(e => e.name) : [],
          modifications: item.edits || []
        })),
        deliveryMethod: 'استلام من الفرع',
        deliveryDetails: {
          branch: selectedBranch.name,
          date: data.date,
          time: data.time
        },
        status: 'مكتمل',
        total: orderTotal,
        discount: percentage ? (percentage / 100) * orderTotal : 0,
        finalTotal: percentage ? orderTotal - (percentage / 100) * orderTotal : orderTotal
      };

      orders.unshift(newOrder);
      localStorage.setItem('orders', JSON.stringify(orders));

      window.open(
        `https://api.whatsapp.com/send?phone=+966500919252&text=${message}`
      );
      toast.success('تم تأكيد الطلب بنجاح');
      navigate('/');
      setCartData([]);
    } catch (error) {
      toast.error('حدث خطأ أثناء معالجة الطلب، يرجى المحاولة مرة أخرى');
    }
  }

  const onSubmit = async (data) => {
    if (!data.search) return;
    
    try {
      const geocoder = new google.maps.Geocoder();
      const results = await new Promise((resolve, reject) => {
        geocoder.geocode({ address: data.search }, (results, status) => {
          if (status === 'OK') {
            resolve(results);
          } else {
            reject(new Error('Geocoding failed'));
          }
        });
      });

      const { lat, lng } = results[0].geometry.location;
      setUserLatLng({ lat: lat(), lng: lng() });
      setMapValue(data?.search);
      // إعادة تعيين قيمة حقل البحث
      resetMap();
    } catch (error) {
      console.error('Geocoding error:', error);
    }
  };

  // center will be user location of exist if not restaurant location
  const center = useMemo(
    () => resLatLng || (restaurants[0]?.coords || { lat: 24.5954, lng: 46.6572 }),
    [resLatLng]
  )

  const handleUserLocationClick = () => {
    if (userLatLng) {
      // إضافة خاصية smooth للتنقل السلس
      mapRef.current.panTo({ 
        lat: userLatLng.lat, 
        lng: userLatLng.lng,
        smooth: true 
      })
    } else {
      toast.error('لا يمكن تحديد موقعك لأن خدمة تحديد المواقع غير مفعلة.')
    }
  }

  // MAP Attributes
  const mapStyles = useMemo(
    () => [
      {
        styles: [
          {
            featureType: 'poi.business',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }],
      },
    ],
    []
  )
  const options = useMemo(
    () => ({
      styles: mapStyles,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      gestureHandling: 'greedy',
    }),
    [mapStyles]
  )
  const onLoad = useCallback((map) => {
    mapRef.current = map
    if (resLatLng) {
      map.panTo(resLatLng)
    }
  }, [resLatLng]) // إزالة center من التبعيات لأنه لم يعد مستخدماً

  // Search
  useEffect(() => {
    if (!watchMap('search')) return
    
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: watchMap('search') }, (results, status) => {
      if (status === 'OK') {
        const { lat, lng } = results[0].geometry.location;
        setUserLatLng({ lat: lat(), lng: lng() });
      } else {
        toast.error('لم يتم العثور على الموقع');
      }
    });
  }, [watchMap, setUserLatLng]);

  // Change location to restautant when change
  useEffect(() => {
    mapRef?.current?.panTo(resLatLng)
  }, [resLatLng])

  // Map Config

  // معالج النقر على الفرع
  const handleResClick = useCallback((res, i) => {
    setValue(i)
    setResLatLng(res.coords)
    setStoreName(res.name)
    
    // تحديث الخريطة مباشرة
    if (mapRef.current) {
      mapRef.current.panTo(res.coords)
    }

    // التمرير إلى الفرع المحدد
    requestAnimationFrame(() => {
      const resContainer = document.getElementById('resContainer')
      const selectedElement = document.getElementById(`branch-${i}`)
      
      if (resContainer && selectedElement) {
        // حساب موقع العنصر المحدد
        const containerHeight = resContainer.clientHeight
        const elementTop = selectedElement.offsetTop
        const elementHeight = selectedElement.clientHeight
        const containerScrollHeight = resContainer.scrollHeight
        
        // حساب الموقع المطلوب للتمرير بحيث يكون العنصر في المنتصف
        let scrollTo = elementTop - (containerHeight / 2) + (elementHeight / 2)
        
        // التأكد من أن التمرير لا يتجاوز حدود المحتوى
        const maxScroll = containerScrollHeight - containerHeight
        scrollTo = Math.max(0, Math.min(scrollTo, maxScroll))
        
        // إضافة تعديل صغير لتحريك البطاقة قليلاً للأسفل
        scrollTo -= elementHeight * 0 // تعديل بنسبة 20% من ارتفاع البطاقة
        
        resContainer.scrollTo({
          top: scrollTo,
          behavior: 'smooth'
        })
      }
    })
  }, [])

  return (
    <div className='absolute w-full h-full'>
      <div className='w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className='w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className='w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1 text-brown-400'>
                استلام من الفرع
              </span>
              <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                {' '}
                Branch Pickup
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 ml-4 rounded-full text-main-900 hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
          title='رجوع'
          onClick={() => navigate(-1)}
        />
      </div>

      <div className='relative max-w-md h-[calc(100vh-150px)] md:h-[calc(100vh-20px)] w-full overflow-hidden flex text flex-col'>
        <GoogleMap
          center={center}
          mapContainerClassName='w-full h-[calc(100vh-250px)] fixed top-16'
          zoom={15}
          options={options}
          onLoad={onLoad}
          gestureHandling={{ greedy: true }}
        >
          {/* موقع المستخدم */}
          {userLatLng && (
            <Marker
              position={userLatLng}
              icon={{
                url: point,
                scaledSize: new google.maps.Size(15, 15),
                zIndex: 1
              }}
            />
          )}
          {/* دبابيس المواقع */}
          {sortedRestaurants.map((res, i) => (
            <Marker
              position={res.coords}
              key={res.id}
              onClick={() => handleResClick(res, i)}
              icon={{
                url: value === i ? marker2 : marker,
                scaledSize: value === i 
                  ? new google.maps.Size(50, 60)
                  : new google.maps.Size(40, 50),
                zIndex: 2
              }}
            />
          ))}

          {/* Search */}
          <form
            onSubmit={handleSubmitMap(onSubmit)}
            className='absolute shadow-md z-40 top-4 left-4 flex items-center overflow-hidden justify-center bg-white rounded-full text-gray-700 group'
          >

          </form>

          {/* Target */}
          <div
            className='absolute z-40 top-48 left-4 w-8 h-8 p-1 flex shadow-md items-center justify-center bg-white rounded-full text-gray-700 cursor-pointer hover:bg-[#ebebeb]'
            onClick={handleUserLocationClick}
            style={{ transition: 'background-color 0.2s ease' }}
          >
            <BiTargetLock className="w-5 h-5" />
          </div>
        </GoogleMap>

        {/* Branch List */}
        <div 
          className={`fixed max-w-md bottom-0 w-full dark:bg-gray-700 flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] transition-all duration-300 ease-in-out animateItems ${
            step === 2 ? 'translate-y-full opacity-0' : 'translate-y-0 opacity-100'
          }`}
        >
          <div
            className='flex flex-col gap-4 lg:h-[180px] h-[200px] overflow-y-auto'
            id='resContainer'
          >
            {sortedRestaurants.map((res, i) => (
              <div
                id={`branch-${i}`}
                className={`p-2 rounded-md bg-gray-100 flex flex-col gap-2 cursor-pointer border-2 ${
                  value === i
                    ? isBranchOpen(res) 
                      ? 'border-main-900 activeRes'
                      : 'border-red-500'
                    : 'border-transparent'
                }`}
                ref={value === i ? activeRef : null}
                onClick={() => handleResClick(res, i)}
                key={res.id}
              >
                <input
                  type='radio'
                  className='hidden'
                  name='store'
                  value={res.name}
                />
                <div className='flex items-center justify-between'>
                  <h4
                    className={`text-lg font-semibold ${
                      isBranchOpen(res) ? 'text-primaryGreen-500' : 'text-gray-400'
                    }`}
                  >
                    {res.name}
                  </h4>
                  <p className='text-xs font-semibold text-main-900'>
                    {userLatLng ?
                      `${storeUserDistance(res.coords.lat, res.coords.lng).toFixed(2)} كم` :
                      ''
                    }
                  </p>
                </div>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center gap-2'>
                    <AiOutlineClockCircle
                      className={`text-lg ${
                        isBranchOpen(res) ? 'text-green-500' : 'text-red-500'
                      }`}
                    />
                    <p
                      className={`text-sm ${
                        isBranchOpen(res) ? 'text-green-500' : 'text-red-500'
                      }`}
                    >
                      {isBranchOpen(res) ? 'مفتوح' : 'مغلق'}
                    </p>
                  </div>
                  <p className={`text-xs ${
                      res.workingHours[days[new Date().getDay()]].open === '00:00' && 
                      res.workingHours[days[new Date().getDay()]].close === '00:00' 
                        ? 'text-red-500' 
                        : 'text-main-900'
                    }`}>
                      {res.open24h || res.workingHours[days[new Date().getDay()]].is24h 
                        ? '24 ساعة' 
                        : res.workingHours[days[new Date().getDay()]].open === '00:00' && 
                          res.workingHours[days[new Date().getDay()]].close === '00:00'
                          ? '00:00 - 00:00'
                          : `${formatTime(res.workingHours[days[new Date().getDay()]].open)} - ${formatTime(res.workingHours[days[new Date().getDay()]].close)}`
                      }
                    </p>
                </div>
                <p className='text-sm text-gray-700'>{res.addrress}</p>
              </div>
            ))}
          </div>
          {sortedRestaurants.length > 0 && (
            <button 
              onClick={() => {
                const selectedBranch = sortedRestaurants[value];
                if (!isBranchOpen(selectedBranch)) {
                  const toastId = 'branch-closed'
                  if (!toast.isActive(toastId)) {
                    toast.error(
                      <div>
                        عذراً، {selectedBranch?.name} مغلق حالياً
                        <br />
                        <span style={{ color: selectedBranch.displayHours === '00:00 - 00:00' ? 'red' : 'inherit' }}>
                          أوقات العمل اليوم: {selectedBranch.displayHours}
                        </span>
                      </div>,
                      {
                        toastId,
                        hideProgressBar: false
                      }
                    )
                  }
                  return
                }
                setStep(2)
              }}
              className={`font-semibold py-2 w-full rounded-full ${
                isBranchOpen(sortedRestaurants[value])
                  ? 'bg-main-900 text-white'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              استلام من {sortedRestaurants[value]?.name}
            </button>
          )}
        </div>

        {/* Pickup Form */}
        <div 
          className={`fixed max-w-md bottom-0 w-full dark:bg-gray-700 flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] transition-all duration-300 ease-in-out ${
            step === 2 ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
          }`}
        >
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className='space-y-3'>
              <div className='flex flex-col text-sm'>
                <label htmlFor='date' className='relative'>
                  <input
                    type='date'
                    id='date'
                    min={currentDate}
                    onInvalid={(e) => {
                      e.target.setCustomValidity(`عذراً، لا يمكن اختيار تاريخ قبل ${currentDate.split('-').reverse().join('-')}`);
                    }}
                    onInput={(e) => e.target.setCustomValidity('')}
                    {...register('date', {
                      required: true
                    })}
                    className={`peer w-full p-3 pr-10 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 placeholder:text-gray-400 placeholder:opacity-0 focus:placeholder:opacity-100 ${
                      !isCurrentDateValid ? 'border-red-500 text-red-500 animate-shake' : errors.date ? 'border-red-500 text-red-500' : 'border-gray-300 focus:border-main-900 text-gray-900'
                    }`}
                  />
                  <span className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 right-10 peer-placeholder-shown:bg-transparent peer-focus:bg-white ${
                    !isCurrentDateValid ? 'text-red-500' : errors.date ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                  }`}>
                    تاريخ الاستلام
                  </span>
                </label>
                {errors.date && (
                  <span className="text-red-500 text-sm block mt-1">
                    {errors.date.type === 'required' && ''}
                  </span>
                )}
              </div>

              <div className='flex flex-col text-sm'>
                <label htmlFor='time' className='relative'>
                  <input
                    type='time'
                    id='time'
                    {...register('time', {
                      required: true
                    })}
                    className={`peer w-full p-3 pr-10 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 placeholder:text-gray-400 placeholder:opacity-0 focus:placeholder:opacity-100 ${
                      !isCurrentTimeValid ? 'border-red-500 text-red-500 animate-shake' : errors.time ? 'border-red-500 text-red-500' : 'border-gray-300 focus:border-main-900 text-gray-900'
                    }`}
                  />
                  <span className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 right-10 peer-placeholder-shown:bg-transparent peer-focus:bg-white ${
                    !isCurrentTimeValid ? 'text-red-500' : errors.time ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                  }`}>
                    وقت الاستلام
                  </span>
                </label>
                {errors.time && (
                  <span className="text-red-500 text-sm mt-1">
                    {errors.time.type === 'required' && ''}
                  </span>
                )}
              </div>

              <div className='flex gap-3 mt-4'>
                <button
                  type='button'
                  onClick={() => {
                    setStep(1)
                    resetForm()
                  }}
                  className='bg-gray-300 text-gray-800 py-2 rounded-full flex-1 dark:bg-gray-900 dark:text-white dark:border-[#111827]'
                >
                  السابق
                </button>
                <button
                  type='submit'
                  className='bg-main-900 text-white py-2 rounded-full flex-1'
                >
                  تأكيد الطلب
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ReciveFromStore
