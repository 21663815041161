import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import '../styles/bottomSheet.css';

export const CustomBottomSheet = ({ children, setModalOn }) => {
  return (
    <div className="relative z-50">
      <BottomSheet 
        open={true} 
        onDismiss={() => setModalOn(false)}
        snapPoints={({ maxHeight }) => [maxHeight * 0.5, maxHeight * 0.9]}
        defaultSnap={({ snapPoints }) => snapPoints[0]}
        expandOnContentDrag={true}
        blocking={true}
        scrollLocking={true}
        className="bottom-sheet-animation"
        sibling={
          <div 
            className="fixed inset-0 z-[400] backdrop-blur-animation"
            aria-hidden="true"
            style={{ backdropFilter: 'blur(3px) saturate(80%)' }}
          />
        }
      >
        <div dir="rtl">
          {children}
        </div>
      </BottomSheet>
    </div>
  );
};

export const CategoriesSheet = ({ setModalOn, data, onSelectCategory }) => {
  return (
    <CustomBottomSheet setModalOn={setModalOn}>
      <div className='flex flex-col gap-3 px-4 pb-4'>
        {data.map((category, i) => (
          <button
            key={i}
            onClick={() => {
              setModalOn(false);
              onSelectCategory(category.id);
            }}
            className='py-3 px-4 rounded-xl w-full flex items-center justify-between bg-gray-100 hover:bg-gray-200 dark:bg-gray-900 text-gray-900 dark:text-gray-100 cursor-pointer transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]'
          >
            <div className='flex items-center gap-3'>
              <div className='w-12 h-12 rounded-lg overflow-hidden bg-gray-200 dark:bg-gray-700'>
                <img 
                  src={category.image || `/images/categories/${category.id}.jpg`} 
                  alt={category.title}
                  className='w-full h-full object-cover'
                  onError={(e) => {
                    e.target.src = '/images/placeholder.jpg';
                  }}
                />
              </div>
              <h5 className='font-semibold text-lg text-gray-900 dark:text-gray-100'>
                {category.title}
              </h5>
            </div>
            <span className='w-9 h-9 rounded-full bg-primaryGreen-500 text-white dark:bg-primaryGreen-600 flex items-center justify-center text-base font-medium'>
              {category.items.length}
            </span>
          </button>
        ))}
      </div>
    </CustomBottomSheet>
  );
};
