/*global google*/

import { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { IoIosArrowBack } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { useForm } from 'react-hook-form'
import { BiTargetLock } from 'react-icons/bi'
import { FaCar } from 'react-icons/fa'
import { IoColorPaletteOutline } from 'react-icons/io5'
import { TbLicense } from 'react-icons/tb'
import { useGlobalContext } from '../context'
import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import marker from '../img/marker2.svg'
import marker2 from '../img/marker.svg'
import point from '../img/point.png'
import { restaurants, isBranchOpen } from '../hooks/branchesManager'

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const CarDelivery = () => {
  const [resLatLng, setResLatLng] = useState(null)
  const [userLatLng, setUserLatLng] = useState(null)
  const [value, setValue] = useState(null)
  const [storeName, setStoreName] = useState('')
  const [step, setStep] = useState(1)
  const activeRef = useRef(null)
  const mapRef = useRef(null)
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const { cartData, setCartData, percentage } = useGlobalContext()

  // compare distance between restaurant and user
  const toRad = (Value) => {
    return (Value * Math.PI) / 180
  }
  
  const calcCrow = useCallback((lat1, lon1, lat2, lon2) => {
    var R = 6371 // km
    var dLat = toRad(lat2 - lat1)
    var dLon = toRad(lon2 - lon1)
    var latitude1 = toRad(lat1)
    var latitude2 = toRad(lat2)
  
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(latitude1) *
        Math.cos(latitude2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d
  }, [])
  
  // Find the distnce between restaurant and user
  const storeUserDistance = useCallback((storeLat, storeLng) => {
    if (!userLatLng) return Infinity
    return calcCrow(userLatLng.lat, userLatLng.lng, storeLat, storeLng)
  }, [userLatLng, calcCrow])

  // مرجع ثابت للمطاعم ودالة التحقق من حالة الفرع
  const restaurantsRef = useRef(restaurants);
  const isBranchOpenRef = useRef(isBranchOpen);


  // ترتيب الفروع حسب المسافة والحالة (مفتوح/مغلق)
  const sortedRestaurants = useMemo(() => {
    if (!userLatLng) return restaurants;
    
    // نسخة من المصفوفة لتجنب تعديل المصفوفة الأصلية
    const sorted = [...restaurants].sort((a, b) => {
      const aOpen = isBranchOpenRef.current(a);
      const bOpen = isBranchOpenRef.current(b);
      
      // أولوية للفروع المفتوحة
      if (aOpen !== bOpen) {
        return aOpen ? -1 : 1;
      }
      
      // إذا كان كلاهما مفتوح أو مغلق، نرتب حسب المسافة
      const distanceA = storeUserDistance(a.coords.lat, a.coords.lng);
      const distanceB = storeUserDistance(b.coords.lat, b.coords.lng);
      return distanceA - distanceB;
    });
    
    return sorted;
  }, [userLatLng, storeUserDistance]);

  // تحديث الفهرس عندما يتغير الترتيب
  useEffect(() => {
    if (storeName && sortedRestaurants.length > 0) {
      const newIndex = sortedRestaurants.findIndex(r => r.name === storeName);
      if (newIndex !== -1) {
        setValue(newIndex);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedRestaurants, storeName]);

  // تحديث التمرير عند تغيير الخطوة
  useEffect(() => {
    const element = document.getElementById(`step-${step}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  // تحديث المسافة بين المستخدم والفروع
  useEffect(() => {
    if (!userLatLng || !restaurants.length) return;

    // تحديث الخريطة لتظهر موقع المستخدم
    if (mapRef.current) {
      mapRef.current.panTo(userLatLng);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLatLng, restaurants]);

  // معالج النقر على الفرع
  const handleResClick = useCallback((res, i) => {
    setValue(i);
    setStoreName(res.name);
    setResLatLng(res.coords);
    
    // تحديث الخريطة مباشرة
    if (mapRef.current) {
      mapRef.current.panTo(res.coords);
    }

    // التمرير إلى الفرع المحدد
    requestAnimationFrame(() => {
      const resContainer = document.getElementById('resContainer');
      const selectedElement = document.getElementById(`branch-${i}`);
      
      if (resContainer && selectedElement) {
        const containerHeight = resContainer.clientHeight;
        const elementTop = selectedElement.offsetTop;
        const elementHeight = selectedElement.clientHeight;
        const containerScrollHeight = resContainer.scrollHeight;
        
        let scrollTo = elementTop - (containerHeight / 2) + (elementHeight / 2);
        const maxScroll = containerScrollHeight - containerHeight;
        scrollTo = Math.max(0, Math.min(scrollTo, maxScroll));
        
        resContainer.scrollTo({
          top: scrollTo,
          behavior: 'smooth'
        });
      }
    });
  }, []);

  // تحديد الفرع الافتراضي عند تحميل الصفحة فقط
  const initialBranchSetRef = useRef(false);

  useEffect(() => {
    // تحديد موقع المستخدم
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude } = location.coords;
          setUserLatLng({ lat: latitude, lng: longitude });
          // إعادة تعيين initialBranchSetRef عند تحديث الموقع
          initialBranchSetRef.current = false;
        },
        (error) => {
          toast.info('يمكنك تفعيل خدمة الموقع لمعرفة أقرب فرع لك')
          
          // في حالة عدم وجود موقع، اختيار أول فرع مفتوح
          const openBranch = restaurants.find(isBranchOpenRef.current)
          if (openBranch) {
            setValue(restaurants.findIndex((res) => res.id === openBranch.id))
            setStoreName(openBranch.name)
            setResLatLng(openBranch.coords)
            if (mapRef.current) {
              mapRef.current.panTo(openBranch.coords)
            }
          } else {
            // إذا كانت كل الفروع مغلقة، اختيار أول فرع
            const defaultBranch = restaurants[0]
            setValue(0)
            setStoreName(defaultBranch.name)
            setResLatLng(defaultBranch.coords)
            if (mapRef.current) {
              mapRef.current.panTo(defaultBranch.coords)
            }
            toast.warning('جميع الفروع مغلقة حالياً')
          }
        }
      )
    } else {
      toast.info('يمكنك تفعيل خدمة الموقع لمعرفة أقرب فرع لك')
      
      // في حالة عدم دعم خدمة الموقع، اختيار أول فرع مفتوح
      const openBranch = restaurants.find(isBranchOpenRef.current)
      if (openBranch) {
        setValue(restaurants.findIndex((res) => res.id === openBranch.id))
        setStoreName(openBranch.name)
        setResLatLng(openBranch.coords)
        if (mapRef.current) {
          mapRef.current.panTo(openBranch.coords)
        }
      } else {
        // إذا كانت كل الفروع مغلقة، اختيار أول فرع
        const defaultBranch = restaurants[0]
        setValue(0)
        setStoreName(defaultBranch.name)
        setResLatLng(defaultBranch.coords)
        if (mapRef.current) {
          mapRef.current.panTo(defaultBranch.coords)
        }
        toast.warning('جميع الفروع مغلقة حالياً')
      }
    }
  }, []);

  // تحديد أقرب فرع مفتوح عند تحديث موقع المستخدم أو الفروع
  useEffect(() => {
    if (!initialBranchSetRef.current && userLatLng && sortedRestaurants.length > 0) {
      // // طباعة الفروع المرتبة للتأكد
      // console.log('الفروع مرتبة حسب المسافة:');
      // sortedRestaurants.forEach((res, i) => {
      //   const distance = storeUserDistance(res.coords.lat, res.coords.lng);
      //   console.log(`${i + 1}. ${res.name} - ${distance.toFixed(2)} كم`);
      // });

      // البحث عن الفروع المفتوحة
      const openBranches = sortedRestaurants.filter(isBranchOpenRef.current);
      const allClosed = openBranches.length === 0;
      
      if (userLatLng) {
        if (!allClosed) {
          // إذا كانت هناك فروع مفتوحة، نختار أقرب فرع مفتوح
          const openBranchesWithDistance = openBranches.map(branch => ({
            ...branch,
            distance: calcCrow(userLatLng.lat, userLatLng.lng, branch.coords.lat, branch.coords.lng)
          }));

          const nearestOpen = openBranchesWithDistance.reduce((prev, curr) => 
            prev.distance < curr.distance ? prev : curr
          );

          const branchIndex = sortedRestaurants.findIndex(res => res.id === nearestOpen.id);
          handleResClick(nearestOpen, branchIndex);
        } else {
          // إذا كانت كل الفروع مغلقة، نختار أقرب فرع مغلق
          const closedBranchesWithDistance = sortedRestaurants.map(branch => ({
            ...branch,
            distance: calcCrow(userLatLng.lat, userLatLng.lng, branch.coords.lat, branch.coords.lng)
          }));

          const nearestClosed = closedBranchesWithDistance.reduce((prev, curr) => 
            prev.distance < curr.distance ? prev : curr
          );

          const branchIndex = sortedRestaurants.findIndex(res => res.id === nearestClosed.id);
          handleResClick(nearestClosed, branchIndex);
        }
      } else {
        // إذا لم يكن موقع المستخدم متاحاً
        if (!allClosed) {
          // إذا كانت هناك فروع مفتوحة، نختار أول فرع مفتوح
          const firstOpen = openBranches[0]
          const branchIndex = sortedRestaurants.findIndex(res => res.id === firstOpen.id);
          handleResClick(firstOpen, branchIndex);
        } else {
          // إذا كانت كل الفروع مغلقة، نختار أول فرع
          handleResClick(sortedRestaurants[0], 0);
        }
      }

      // إظهار تنبيه إذا كانت كل الفروع مغلقة
      if (allClosed) {
        toast.warning('جميع الفروع مغلقة حالياً')
      }

      initialBranchSetRef.current = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLatLng, sortedRestaurants, handleResClick, calcCrow]);

  // تحديث التمرير عند تغيير الخطوة
  useEffect(() => {
    if (step === 1 && value !== null) {
      requestAnimationFrame(() => {
        const resContainer = document.getElementById('resContainer');
        const selectedElement = document.getElementById(`branch-${value}`);
        
        if (resContainer && selectedElement) {
          const containerHeight = resContainer.clientHeight;
          const elementTop = selectedElement.offsetTop;
          const elementHeight = selectedElement.clientHeight;
          const containerScrollHeight = resContainer.scrollHeight;
          
          let scrollTo = elementTop - (containerHeight / 2) + (elementHeight / 2);
          const maxScroll = containerScrollHeight - containerHeight;
          scrollTo = Math.max(0, Math.min(scrollTo, maxScroll));
          
          resContainer.scrollTo({
            top: scrollTo,
            behavior: 'smooth'
          });
        }
      });
    }
  }, [step, value]);

  // تحديث عنوان الزر
  useEffect(() => {
  }, [value, storeName]);

  // Get User location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude } = location.coords;
          setUserLatLng({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Error getting location:', error);
          toast.error('لم نتمكن من تحديد موقعك');
        }
      );
    }
  }, []);

  // حساب المجموع والخصم
  const orderTotal = cartData.reduce((acc, item) => acc + item.price, 0)
  const total = orderTotal.toLocaleString('en-US')
  const discount = ((percentage / 100) * orderTotal).toLocaleString('en-US')
  const totaAfterDiscount = (
    orderTotal -
    (percentage / 100) * orderTotal
  ).toLocaleString('en-US')

  const formatTime = (time) => {
    if (typeof time === 'string') {
      const [hours, minutes] = time.split(':')
      const hour = parseInt(hours)
      const ampm = hour >= 12 ? 'م' : 'ص'
      const formattedHour = hour % 12 || 12
      return `${formattedHour}:${minutes} ${ampm}`
    }
    return time
  }

  const onSubmit = (data) => {
    if (!storeName) {
      toast.error('الرجاء اختيار الفرع')
      return
    }

    const selectedBranchData = restaurantsRef.current.find(branch => branch.name === storeName)
    if (!isBranchOpenRef.current(selectedBranchData)) {
      const toastId = 'branch-closed';
      
      if (!toast.isActive(toastId)) {
        toast.error(
          <div>
            عذراً، {selectedBranchData.name} مغلق حالياً
            <br />
            {selectedBranchData && selectedBranchData.displayHours ? (
              <span style={{ color: selectedBranchData.displayHours === '00:00 - 00:00' ? 'red' : 'inherit' }}>
                أوقات العمل اليوم: {selectedBranchData.displayHours}
              </span>
            ) : ''}
          </div>,
          { toastId }
        );
      }
      return;
    }

    // Get the last order number from localStorage
    const orders = JSON.parse(localStorage.getItem('orders') || '[]')
    const lastOrderNumber = orders.length > 0 ? parseInt(orders[0].orderId) || 0 : 0
    const newOrderNumber = lastOrderNumber + 1

    const message = `*نوع الطلب* : تسليم للسيارة 🚗%0A*رقم الطلب* : 0000${newOrderNumber}%0A*اسم الفرع* : ${storeName}%0A*معلومات السيارة* :%0A    • النوع : ${data.carModel}%0A    • اللون : ${data.carColor}%0A    • رقم اللوحة : ${data.plateNumber}%0A---------------------------%0A${cartData
      .map((item) => {
        let size = ''
        let extras = ''
        let edits = ''
        
        if (item.size) {
          switch (item.size.name) {
            case 'small':
              size = 'صغير'
              break
            case 'medium':
              size = 'وسط'
              break
            case 'large':
              size = 'كبير'
              break
            default:
              size = ''
          }
        }

        if (item.extra && item.extra.length > 0) {
          extras = `%0A*الإضافات* :%0A    • ${item.extra.map(e => e.name).join('%0A    • ')}`;
        }
        
        if (item.edits && item.edits.length > 0) {
          edits = `%0A*التعديلات* :%0A    • ${item.edits.join('%0A    • ')}`;
        }

        return `*الصنف* : ${item.name}%0A${
          size ? `*الحجم* : ${size}%0A` : ''
        }*الكمية* : ${item.quantity || 1}%0A*السعر* : ${
          item.price
        } ريال${extras}${edits}`;
      })
      .join('%0A---------------------------%0A')}%0A---------------------------%0A%0A*مبلغ الطلب* : ${total} ريال%0A*المجموع* : ${orderTotal.toLocaleString(
        'en-US'
      )} ريال${
        percentage
          ? `%0A*الخصم* : ${discount} ريال%0A*المجموع بعد الخصم* : ${totaAfterDiscount} ريال`
          : ''
      }%0A*وقت الطلب* : ${formatTime(new Date().toLocaleTimeString('ar-SA', { hour12: true }))}`

    try {
      // حفظ الطلب في localStorage
      const newOrder = {
        orderId: newOrderNumber,
        date: new Date().toISOString(),
        items: cartData.map(item => ({
          name: item.name,
          price: item.price,
          quantity: item.quantity || 1,
          image: item.img,
          size: item.size ? (
            item.size.name === 'small' ? 'صغير' : 
            item.size.name === 'medium' ? 'وسط' : 
            item.size.name === 'large' ? 'كبير' : ''
          ) : 'وسط',
          additions: item.extra ? item.extra.map(e => e.name) : [],
          modifications: item.edits || []
        })),
        deliveryMethod: 'تسليم للسيارة',
        deliveryDetails: {
          branch: storeName,
          carInfo: {
            model: data.carModel,
            color: data.carColor,
            plateNumber: data.plateNumber
          }
        },
        status: 'مكتمل',
        total: orderTotal,
        discount: percentage ? (percentage / 100) * orderTotal : 0,
        finalTotal: percentage ? orderTotal - (percentage / 100) * orderTotal : orderTotal
      }

      orders.unshift(newOrder)
      localStorage.setItem('orders', JSON.stringify(orders))

      window.open(
        `https://api.whatsapp.com/send?phone=+966500919252&text=${message}`
      )
      toast.success('تم تأكيد الطلب بنجاح')
      navigate('/')
      setCartData([])
    } catch (error) {
      console.error('Error processing order:', error)
      toast.error('حدث خطأ أثناء معالجة الطلب')
    }
  }

  const mapStyles = useMemo(
    () => [
      {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }],
      },
    ],
    []
  )

  const center = useMemo(
    () => resLatLng,
    [resLatLng]
  )

  const options = useMemo(
    () => ({
      styles: mapStyles,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      gestureHandling: 'greedy'
    }),
    [mapStyles]
  )

  const onLoad = useCallback((map) => {
    mapRef.current = map
    if (resLatLng) {
      map.panTo(resLatLng)
    }
  }, [resLatLng])

  // تهيئة الخريطة
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

  return (
    <div className='absolute w-full h-full'>
      <div className='w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className='w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className='w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1 text-brown-400'>
                التسليم للسيارة
              </span>
              <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                {' '}
                Car Delivery
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 ml-4 rounded-full text-main-900 hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
          title='رجوع'
          onClick={() => navigate(-1)}
        />
      </div>

      <div className='relative max-w-md h-[calc(100vh-150px)] md:h-[calc(100vh-20px)] w-full overflow-hidden flex text flex-col'>
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerClassName='w-full h-[calc(100vh-250px)] fixed top-16'
          options={options}
          onLoad={onLoad}
          apiKey={apiKey}
        >
          {/* موقع المستخدم */}
          {userLatLng && (
            <Marker
              position={userLatLng}
              icon={{
                url: point,
                scaledSize: new google.maps.Size(15, 15),
                zIndex: 1
              }}
            />
          )}

          {/* مواقع الفروع */}
          {sortedRestaurants.map((res, i) => (
            <Marker
              position={res.coords}
              key={res.id}
              onClick={() => handleResClick(res, i)}
              icon={{
                url: value === i ? marker2 : marker,
                scaledSize: value === i 
                  ? new google.maps.Size(50, 60)
                  : new google.maps.Size(40, 50),
                zIndex: 2
              }}
            />
          ))}

          {/* زر موقع المستخدم */}
          <div
            className='absolute z-40 top-48 left-4 w-8 h-8 p-1 flex shadow-md items-center justify-center bg-white rounded-full text-gray-700 cursor-pointer hover:bg-[#ebebeb]'
            onClick={() => {
              if (userLatLng) {
                mapRef.current?.panTo(userLatLng)
              } else {
                toast.info('يرجى تفعيل خدمة تحديد الموقع')
              }
            }}
            style={{ transition: 'background-color 0.2s ease' }}
          >
            <BiTargetLock className="w-5 h-5" />
          </div>
        </GoogleMap>

        {step === 1 ? (
          <div className='fixed max-w-md bottom-0 w-full dark:bg-gray-700 flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems'>
            {/* Restaurants */}
            <div
              className='flex flex-col gap-4 lg:h-[180px] h-[200px] overflow-y-auto'
              id='resContainer'
            >
              {sortedRestaurants.map((res, i) => (
                <div
                  id={`branch-${i}`}
                  className={`p-2 rounded-md bg-gray-100 flex flex-col gap-2 cursor-pointer border-2 ${
                    value === i
                      ? isBranchOpenRef.current(res) 
                        ? 'border-main-900 activeRes'
                        : 'border-red-500'
                      : isBranchOpenRef.current(res) 
                        ? 'border-transparent' 
                        : 'border-transparent'
                  }`}
                  ref={value === i ? activeRef : null}
                  onClick={() => handleResClick(res, i)}
                  key={res.id}
                >
                  <input
                    type='radio'
                    className='hidden'
                    name='store'
                    value={res.name}
                  />
                  <div className='flex items-center justify-between'>
                    <h4
                      className={`text-lg font-semibold ${
                        isBranchOpenRef.current(res) ? 'text-primaryGreen-500' : 'text-gray-400'
                      }`}
                    >
                      {res.name}
                    </h4>
                    <p className='text-xs font-semibold text-main-900'>
                      {userLatLng ?
                        `${storeUserDistance(res.coords.lat, res.coords.lng).toFixed(2)} كم` :
                        ''
                      }
                    </p>
                  </div>
                  <div className='flex items-center justify-between'>
                    <div className='flex items-center gap-2'>
                      <AiOutlineClockCircle
                        className={`text-lg ${
                          isBranchOpenRef.current(res) ? 'text-green-500' : 'text-red-500'
                        }`}
                      />
                      <p
                        className={`text-sm ${
                          isBranchOpenRef.current(res) ? 'text-green-500' : 'text-red-500'
                        }`}
                      >
                        {isBranchOpenRef.current(res) ? 'مفتوح' : 'مغلق'}
                      </p>
                    </div>
                    <p className={`text-xs ${
                      res.workingHours[days[new Date().getDay()]].open === '00:00' && 
                      res.workingHours[days[new Date().getDay()]].close === '00:00' 
                        ? 'text-red-500' 
                        : 'text-main-900'
                    }`}>
                      {res.open24h || res.workingHours[days[new Date().getDay()]].is24h 
                        ? '24 ساعة' 
                        : res.workingHours[days[new Date().getDay()]].open === '00:00' && 
                          res.workingHours[days[new Date().getDay()]].close === '00:00'
                          ? '00:00 - 00:00'
                          : `${formatTime(res.workingHours[days[new Date().getDay()]].open)} - ${formatTime(res.workingHours[days[new Date().getDay()]].close)}`
                      }
                    </p>
                  </div>
                  <p className='text-sm text-gray-700'>{res.addrress}</p>
                </div>
              ))}
            </div>
            {sortedRestaurants.length > 0 && (
              <button 
                className={`font-semibold py-2 w-full rounded-full ${
                  sortedRestaurants[value] && isBranchOpenRef.current(sortedRestaurants[value])
                    ? 'bg-main-900 text-white'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
                onClick={() => {
                  const selectedBranch = sortedRestaurants[value];
                  if (!selectedBranch || !isBranchOpenRef.current(selectedBranch)) {
                    const toastId = 'branch-closed';
                    
                    if (!toast.isActive(toastId)) {
                      toast.error(
                        <div>
                          عذراً، {selectedBranch?.name || 'الفرع'} مغلق حالياً
                          <br />
                          {selectedBranch && selectedBranch.displayHours ? (
                            <span style={{ color: selectedBranch.displayHours === '00:00 - 00:00' ? 'red' : 'inherit' }}>
                              أوقات العمل اليوم: {selectedBranch.displayHours}
                            </span>
                          ) : ''}
                        </div>,
                        { toastId }
                      );
                    }
                    return;
                  }
                  setStep(2);
                }}
              >
                {sortedRestaurants[value] ? `تسليم للسيارة من ${sortedRestaurants[value].name}` : 'اختر فرعاً'}
              </button>
            )}
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className='fixed max-w-md bottom-0 w-full dark:bg-gray-700 flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems'>
            <div className='space-y-3'>
              <div className='flex flex-col text-sm'>
                <label htmlFor='carModel' className='relative'>
                  <input
                    type='text'
                    id='carModel'
                    {...register('carModel', { required: true })}
                    className={`peer w-full p-3 pr-10 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 placeholder:text-gray-400 placeholder:opacity-0 focus:placeholder:opacity-100 ${
                      errors.carModel ? 'border-red-500 text-red-500 animate-shake' : 'border-gray-300 focus:border-main-900 text-gray-900'
                    }`}
                    placeholder='مثال : كامري'
                  />
                  <FaCar className={`absolute right-3 top-1/2 -translate-y-1/2 text-lg ${
                    errors.carModel ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                  }`} />
                  <span className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 right-10 peer-placeholder-shown:bg-transparent peer-focus:bg-white ${
                    errors.carModel ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                  }`}>
                    نوع السيارة
                  </span>
                </label>
              </div>

              <div className='flex flex-col text-sm'>
                <label htmlFor='carColor' className='relative'>
                  <input
                    type='text'
                    id='carColor'
                    {...register('carColor', { required: true })}
                    className={`peer w-full p-3 pr-10 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 placeholder:text-gray-400 placeholder:opacity-0 focus:placeholder:opacity-100 ${
                      errors.carColor ? 'border-red-500 text-red-500 animate-shake' : 'border-gray-300 focus:border-main-900 text-gray-900'
                    }`}
                    placeholder='مثال : أبيض'
                  />
                  <IoColorPaletteOutline className={`absolute right-3 top-1/2 -translate-y-1/2 text-lg ${
                    errors.carColor ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                  }`} />
                  <span className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 right-10 peer-placeholder-shown:bg-transparent peer-focus:bg-white ${
                    errors.carColor ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                  }`}>
                    لون السيارة
                  </span>
                </label>
              </div>

              <div className='flex flex-col text-sm'>
                <label htmlFor='plateNumber' className='relative'>
                  <input
                    type='text'
                    id='plateNumber'
                    {...register('plateNumber', { required: true })}
                    className={`peer w-full p-3 pr-10 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 placeholder:text-gray-400 placeholder:opacity-0 focus:placeholder:opacity-100 ${
                      errors.plateNumber ? 'border-red-500 text-red-500 animate-shake' : 'border-gray-300 focus:border-main-900 text-gray-900'
                    }`}
                    placeholder='مثال : 1234 أ ب ج'
                  />
                  <TbLicense className={`absolute right-3 top-1/2 -translate-y-1/2 text-lg ${
                    errors.plateNumber ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                  }`} />
                  <span className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 right-10 peer-placeholder-shown:bg-transparent peer-focus:bg-white ${
                    errors.plateNumber ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                  }`}>
                    رقم اللوحة
                  </span>
                </label>
              </div>

              <div className='flex gap-3 mt-4'>
                <button
                  type='button'
                  onClick={() => {
                    setStep(1);
                    reset(); // إعادة تعيين نموذج react-hook-form
                  }}
                  className='bg-gray-300 text-gray-800 py-2 rounded-full flex-1 dark:bg-gray-900 dark:text-white dark:border-[#111827]'
                >
                  السابق
                </button>
                <button
                  type='submit'
                  className='bg-main-900 text-white py-2 rounded-full flex-1'
                >
                  تأكيد الطلب
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default CarDelivery
