import React, { useState } from 'react';
import Slider from 'react-slick';
import BannerLoader from './BannerLoader';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Banner = () => {
  const [isBannerVisible] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});

  const bannerImages = [
    "https://mostaql.hsoubcdn.com/uploads/portfolios/1174804/64e1debc1e6a6/%D8%A8%D9%86%D8%B11-0%D9%A1.jpg",
    "https://apis.alromansiah.com/images/banners/banners-1727046818.png",
    "https://apis.alromansiah.com/images/banners/banners-1728667060.png",
    "https://apis.alromansiah.com/images/banners/banners-1732781510.png"
  ];

  const settings = {
    autoplay: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    swipe: true,
    touchThreshold: 10,
    arrows: false,
    initialSlide: 2,
  };

  const handleImageLoad = (index) => {
    setLoadedImages(prev => ({
      ...prev,
      [index]: true
    }));
  };

  if (!isBannerVisible) {
    return null;
  }

  return (
    <div className="banner-container relative overflow-hidden">
      <Slider {...settings} className="relative">
        {bannerImages.map((imageUrl, index) => (
          <div key={index} className="slide relative" aria-hidden="true" tabIndex="-1">
            {!loadedImages[index] && <BannerLoader />}
            <img 
              src={imageUrl}
              alt={`Banner ${index + 1}`}
              tabIndex={index + 1}
              className={`w-full h-auto object-cover ${!loadedImages[index] ? 'hidden' : ''}`}
              onLoad={() => handleImageLoad(index)}
              loading="lazy"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const App = () => {
  const [isBannerVisible] = useState(true);

  return (
    <div>
      {isBannerVisible && <Banner />}
    </div>
  );
};

export default App;
