import { AiOutlineClose } from 'react-icons/ai'

const Modal = ({ children, title, setModalOn, className }) => {
  const handleClose = (e) => {
    if (e.target.id === 'container') setModalOn(false)
  }
  return (
    <div
      className={`fixed px-4 inset-0 flex items-center justify-center bg-black z-50 bg-transparent !m-0`}
      id='container'
      onClick={handleClose}
    >
      <div
        className={`w-full scrollbar max-h-[600px] p-4 rounded-lg md:w-[600px] mx-auto bg-white flex flex-col dark:bg-gray-700 shadow-2xl ${className}`}
      >
        <div className='flex justify-between items-center border-b border-gray-300 dark:text-gray-50 pb-2'>
          <h4 className='font-semibold text-lg'>{title}</h4>
          <button>
            <AiOutlineClose
              className='eax2 w-7 h-7 p-0.5 rounded-full bg-red-600 text-gray-50 hover:bg-opacity-100 opacity-80 dark:bg-red-600 dark:text-gray-50 block transform hover:rotate-180 cursor-pointer transition duration-300 ease'
              onClick={() => setModalOn(false)}
            />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
