import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack, IoMdCamera } from 'react-icons/io';
import { IoPersonOutline, IoLanguageOutline, IoKeyOutline, IoMailOutline, IoCallOutline, IoMaleFemaleOutline } from 'react-icons/io5';

export default function Profile() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  
  const [profileData, setProfileData] = useState(() => {
    const savedData = localStorage.getItem('profileData');
    return savedData ? JSON.parse(savedData) : {
      name: 'ابو عبدالله',
      phone: '96650091252',
      email: 'z9@hotmail.com',
      gender: 'ذكر',
      profileImage: null,
      password: '',
      preferredLanguage: 'ar'
    };
  });

  const [isEditing, setIsEditing] = useState({
    name: false,
    phone: false,
    email: false,
    gender: false,
    password: false,
    preferredLanguage: false
  });

  const [editValues, setEditValues] = useState({
    name: '',
    phone: '',
    email: '',
    gender: '',
    password: '',
    preferredLanguage: ''
  });

  useEffect(() => {
    localStorage.setItem('profileData', JSON.stringify(profileData));
  }, [profileData]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileData(prev => ({
          ...prev,
          profileImage: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEdit = (field) => {
    setEditValues(prev => ({
      ...prev,
      [field]: profileData[field]
    }));
    setIsEditing(prev => ({
      ...prev,
      [field]: true
    }));
  };

  const handleSave = (field) => {
    const newValue = editValues[field];
    // التحقق من أن القيمة ليست فارغة
    if (newValue.trim() === '') {
      return;
    }
    
    setProfileData(prev => ({
      ...prev,
      [field]: newValue
    }));
    setIsEditing(prev => ({
      ...prev,
      [field]: false
    }));
  };

  const handleChange = (field, value) => {
    setEditValues(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const renderField = (label, field, value) => {
    const getIcon = () => {
      const iconStyle = "text-[#b3b3b3] w-5 h-5 ml-2"; // Using the same class as password and language icons
      switch(field) {
        case 'name':
          return <IoPersonOutline className={iconStyle} />;
        case 'phone':
          return <IoCallOutline className={iconStyle} />;
        case 'email':
          return <IoMailOutline className={iconStyle} />;
        case 'gender':
          return <IoMaleFemaleOutline className={iconStyle} />;
        case 'password':
          return <IoKeyOutline className={iconStyle} />;
        case 'preferredLanguage':
          return <IoLanguageOutline className={iconStyle} />;
        default:
          return null;
      }
    };

    return (
      <div className="border-b border-[#005544] py-4 first:pt-0 last:border-0 last:pb-0">
        <div className="flex flex-col w-full">
          <span className="text-[#b3b3b3] text-sm mb-2 flex items-center gap-1">
            {getIcon()} {label}
          </span>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              {isEditing[field] ? (
                <input
                  type="text"
                  value={editValues[field]}
                  onChange={(e) => handleChange(field, e.target.value)}
                  className="bg-transparent border-none focus:outline-none text-white text-right flex-1"
                  dir={field === 'phone' || field === 'email' ? 'ltr' : 'rtl'}
                  autoFocus
                />
              ) : (
                <span 
                  className="text-white text-right flex-1"
                  dir={field === 'phone' || field === 'email' ? 'ltr' : 'rtl'}
                >
                  {field === 'phone' ? '+' + value : value}
                </span>
              )}
            </div>
            <button
              onClick={() => isEditing[field] ? handleSave(field) : handleEdit(field)}
              className="px-6 py-1.5 rounded-full border border-[#004d3f] bg-[#003f34] text-white text-sm mr-4"
            >
              {isEditing[field] ? 'حفظ' : 'تعديل'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderGenderField = () => {
    return (
      <div className="border-b border-[#005544] py-4 last:border-0 last:pb-0">
        <div className="flex flex-col w-full">
          <span className="text-[#b3b3b3] text-sm mb-2 flex items-center gap-1">
            <IoMaleFemaleOutline className="text-[#b3b3b3] w-5 h-5 ml-2" /> الجنس
          </span>
          <div className="flex justify-between items-center">
            {isEditing.gender ? (
              <div className="flex gap-4 flex-1">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="ذكر"
                    checked={editValues.gender === 'ذكر'}
                    onChange={(e) => handleChange('gender', e.target.value)}
                    className="hidden"
                  />
                  <span className={`px-6 py-1.5 rounded-full border cursor-pointer ${
                    editValues.gender === 'ذكر' 
                    ? 'border-white bg-white text-[#004d3f]' 
                    : 'border-[#004d3f] bg-[#003f34] text-white'
                  } text-sm`}>
                    ذكر
                  </span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="أنثى"
                    checked={editValues.gender === 'أنثى'}
                    onChange={(e) => handleChange('gender', e.target.value)}
                    className="hidden"
                  />
                  <span className={`px-6 py-1.5 rounded-full border cursor-pointer ${
                    editValues.gender === 'أنثى'
                    ? 'border-white bg-white text-[#004d3f]' 
                    : 'border-[#004d3f] bg-[#003f34] text-white'
                  } text-sm`}>
                    أنثى
                  </span>
                </label>
              </div>
            ) : (
              <span className="text-white text-right flex-1">
                {profileData.gender}
              </span>
            )}
            <button
              onClick={() => isEditing.gender ? handleSave('gender') : handleEdit('gender')}
              className="px-6 py-1.5 rounded-full border border-[#004d3f] bg-[#003f34] text-white text-sm mr-4"
            >
              {isEditing.gender ? 'حفظ' : 'تعديل'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderProfileImage = () => {
    return (
      <div className="relative -mb-16 z-10 mt-20">
        <div className="flex justify-center">
          <div className="relative">
            <div className="w-32 h-32 rounded-full overflow-hidden bg-[#003f34] flex items-center justify-center border-4 shadow-lg">
              {profileData.profileImage ? (
                <img 
                  src={profileData.profileImage} 
                  alt="Profile" 
                  className="w-full h-full object-cover"
                />
              ) : (
                <IoPersonOutline className="w-20 h-20 text-white" />
              )}
            </div>
            <button 
              onClick={() => fileInputRef.current?.click()}
              className="absolute bottom-0 right-0 w-10 h-10 bg-[#004d3f] rounded-full flex items-center justify-center cursor-pointer border-2 shadow-md"
            >
              <IoMdCamera className="text-white w-6 h-6" />
            </button>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderLanguageField = () => {
    return (
      <div className="border-b border-[#005544] py-4 last:border-0 last:pb-0">
        <div className="flex flex-col w-full">
          <div className="flex items-center mb-2">
            <IoLanguageOutline className="text-[#b3b3b3] w-5 h-5 ml-2" />
            <span className="text-[#b3b3b3] text-sm">اللغة المفضلة</span>
          </div>
          <div className="flex justify-between items-center">
            {isEditing.preferredLanguage ? (
              <div className="flex gap-4 flex-1">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="language"
                    value="ar"
                    checked={editValues.preferredLanguage === 'ar'}
                    onChange={(e) => handleChange('preferredLanguage', e.target.value)}
                    className="hidden"
                  />
                  <span className={`px-6 py-1.5 rounded-full border cursor-pointer ${
                    editValues.preferredLanguage === 'ar'
                    ? 'border-white bg-white text-[#004d3f]'
                    : 'border-[#004d3f] bg-[#003f34] text-white'
                  } text-sm`}>
                    العربية
                  </span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="language"
                    value="en"
                    checked={editValues.preferredLanguage === 'en'}
                    onChange={(e) => handleChange('preferredLanguage', e.target.value)}
                    className="hidden"
                  />
                  <span className={`px-6 py-1.5 rounded-full border cursor-pointer ${
                    editValues.preferredLanguage === 'en'
                    ? 'border-white bg-white text-[#004d3f]'
                    : 'border-[#004d3f] bg-[#003f34] text-white'
                  } text-sm`}>
                    English
                  </span>
                </label>
              </div>
            ) : (
              <span className="text-white text-right flex-1">
                {profileData.preferredLanguage === 'ar' ? 'العربية' : 'English'}
              </span>
            )}
            <button
              onClick={() => isEditing.preferredLanguage ? handleSave('preferredLanguage') : handleEdit('preferredLanguage')}
              className="px-6 py-1.5 rounded-full border border-[#004d3f] bg-[#003f34] text-white text-sm mr-4"
            >
              {isEditing.preferredLanguage ? 'حفظ' : 'تعديل'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-800 relative pb-20">
      <div className="w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700">
        <div className="col-span-9 grid grid-cols-12 justify-start items-center">
          <Link
            to="/"
            className="col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2"
          >
            <IoPersonOutline className="w-8 h-8 text-main-900" />
            <h1 className="font-extrabold flex gap-1 flex-col text-md text-main-900 dark:text-white">
              <span className="inline-block transform translate-y-1 text-brown-400">
                الملف الشخصي
              </span>
              <span className="font-extrabold text-md text-main-900 dark:text-white overflow-hidden">
              User Profile
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className="w-10 h-10 rounded-full text-main-900 hover:bg-main-900 hover:text-white dark:text-white transition p-2 cursor-pointer ml-4"
          title="رجوع"
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="w-full h-full overflow-y-auto">
        <div className="max-w-md w-full mx-auto px-4">
          {renderProfileImage()}
          <div className="bg-[#004d3f] rounded-xl p-4 pt-20">
            {renderField('الاسم', 'name', profileData.name)}
            {renderField('رقم الجوال', 'phone', profileData.phone)}
            {renderField('البريد الإلكتروني', 'email', profileData.email)}
            {renderGenderField()}
            {renderLanguageField()}
          </div>
        </div>
      </div>
    </div>
  );
}
