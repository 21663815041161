import React from 'react';

const BannerLoader = () => {
  return (
    <div className="banner-container relative overflow-hidden">
      <div className="w-full h-48 sm:h-64 md:h-72 lg:h-96 bg-gray-200 dark:bg-gray-800 animate-pulse rounded-lg">
        {/* عناصر تحميل إضافية داخل البنر */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="space-y-4 w-3/4">
            {/* محاكاة عنوان */}
            <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded-lg w-1/2 mx-auto" />
            {/* محاكاة وصف */}
            <div className="h-4 bg-gray-300 dark:bg-gray-700 rounded-lg w-3/4 mx-auto" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerLoader;
