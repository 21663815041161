import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { IoIosArrowBack } from 'react-icons/io'

import tableImg from '../img/table.png'
import activeTable from '../img/active-table.png'
import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import { useGlobalContext } from '../context'

import 'react-toastify/dist/ReactToastify.css'

const TableNumber = () => {
  const [tableOn, setTableOn] = useState(false)
  const [value, setValue] = useState(null)
  const [tabValue, setTabValue] = useState(0)

  const navigate = useNavigate()
  const { cartData, setCartData, percentage } = useGlobalContext()

  const tabs = ['قسم الافراد','قسم العائلات']

  // Get the last order number
  const orders = JSON.parse(localStorage.getItem('orders') || '[]')
  const lastOrderNumber = orders.length > 0 ? parseInt(orders[0].orderId) || 0 : 0
  const orderNumber = lastOrderNumber + 1

  // Handle Message
  const total = cartData
    .reduce((acc, item) => acc + item.price, 0)
    .toLocaleString('en-US')
  const date = new Date(Date.now())
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'مساءً' : 'صباحاً'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const time = `${hours}:${minutes} ${ampm}`

  const orderTotal = cartData.reduce((acc, item) => acc + item.price, 0)

  const discount = ((percentage / 100) * orderTotal).toLocaleString('en-US')
  const totaAfterDiscount = (
    orderTotal -
    (percentage / 100) * orderTotal
  ).toLocaleString('en-US')

  const message = `%0A%2Aنوع الطلب%2A %3A محلي %0A%2Aرقم الطلب%2A %3A 0000${orderNumber} %0A%2Aالقسم%2A %3A ${tabs[tabValue]} %0A%2Aرقم الطاولة%2A %3A ${value} %0A---------------------------%0A${cartData
    .map((item) => {
      let itemMessage = `%2Aالصنف%2A %3A ${item.name} %0A`

      if (item.size) {
        let size = ''
        switch (item.size.name) {
          case 'small':
            size = 'صغير'
            break
          case 'medium':
            size = 'وسط'
            break
          case 'large':
            size = 'كبير'
            break
          default:
            size = ''
        }
        if (size) {
          itemMessage += `%2Aالحجم%2A %3A ${size} %0A`
        }
      }

      itemMessage += `%2Aالكمية%2A %3A ${item.quantity} %0A%2Aالسعر%2A %3A ${item.price} ريال`

      if (item.extra && item.extra.length > 0) {
        itemMessage += `%0A%2Aالإضافات%2A %3A %0A${item.extra
          .map((e) => `• ${e.name}`)
          .join('%0A')}`
      }
      
      if (item.edits && item.edits.length > 0) {
        itemMessage += `%0A%2Aالتعديلات%2A %3A %0A${item.edits
          .map((e) => `• ${e}`)
          .join('%0A')}`
      }

      return itemMessage
    })
    .join(
      '%0A---------------------------%0A'
    )}%0A---------------------------%0A%0A*مبلغ الطلب* %3A ${total} ريال %0A*المجموع* %3A ${orderTotal.toLocaleString(
    'en-US'
  )} ريال %0A${
    percentage
      ? `*الخصم* %3A ${discount} ريال %0A*المجموع بعد الخصم* %3A ${totaAfterDiscount} ريال %0A*`
      : ''
  }*وقت الطلب* %3A ${time}`

  const confirmOrder = () => {
    // حفظ رقم الطاولة في localStorage
    localStorage.setItem('tableNumber', value);
    console.log('TableNumber.js - Saved table number:', value);
    
    const deliveryType = localStorage.getItem('deliveryType');
    console.log('TableNumber.js - Current delivery type:', deliveryType);

    // حفظ الطلب في localStorage
    const orders = JSON.parse(localStorage.getItem('orders') || '[]');
    const lastOrderNumber = orders.length > 0 ? parseInt(orders[0].orderId) || 0 : 0;
    const newOrderNumber = lastOrderNumber + 1;

    // تحويل نوع التوصيل إلى العربية
    const getDeliveryMethod = (type) => {
      console.log('TableNumber.js - Converting delivery type:', type);
      let result;
      switch (type) {
        case 'in_restaurant':
          result = 'محلي';
          break;
        case 'deliver_home':
          result = 'توصيل';
          break;
        case 'bike':
          result = 'استلام من الفرع';
          break;
        default:
          console.log('TableNumber.js - Unknown delivery type:', type);
          result = type || 'توصيل';
      }
      console.log('TableNumber.js - Converted to:', result);
      return result;
    };

    const deliveryMethod = getDeliveryMethod(deliveryType);
    console.log('TableNumber.js - Final delivery method:', deliveryMethod);

    const newOrder = {
      orderId: newOrderNumber,
      date: new Date().toISOString(),
      items: cartData.map(item => ({
        name: item.name,
        price: item.price,
        quantity: item.quantity || 1,
        image: item.img,
        size: item.size?.name || 'وسط',
        additions: item.extra?.map(e => e.name) || [],
        modifications: item.edits || []
      })),
      deliveryMethod: deliveryMethod,
      deliveryDetails: {
        tableNumber: deliveryType === 'in_restaurant' ? value : null,
        address: deliveryType === 'deliver_home' ? localStorage.getItem('deliveryAddress') : null,
        branch: deliveryType === 'bike' ? localStorage.getItem('branchName') : null
      },
      status: 'مكتمل',
      total: cartData.reduce((acc, item) => acc + item.price, 0)
    };

    console.log('TableNumber.js - Created new order:', newOrder);
    orders.unshift(newOrder);
    localStorage.setItem('orders', JSON.stringify(orders));
    
    window.open(
      `https://api.whatsapp.com/send?phone=+966500919252&text=${message}`
    )
    toast.success('تم تأكيد الطلب بنجاح')
    setTableOn(false)
    navigate('/')
    setCartData([])
  }

  const handleClose = (e) => {
    if (e.target.id === 'container') {
      setTableOn(false)
      setValue(null)
      console.log('cliekd outside')
    }
  }

  const numOfTables =
    tabValue === 0
      ? Array.from({ length: 12 }, (_, i) => i + 1)
      : Array.from({ length: 9 }, (_, i) => i + 1)

  return (
    <>
      <div className='w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className=' w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className=' w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1  text-brown-400'>
                اختيار الطاولة
              </span>
              <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                {' '}
                Choose Table
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 ml-4 rounded-full text-main-900 hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
          title='رجوع'
          onClick={() => navigate(-1)}
        />
      </div>

      <div
        id='container'
        onClick={handleClose}
        className='animateItems min-h-[calc(100vh-225px)] pt-[64px] md:min-h-[calc(100vh-136px)] dark:bg-gray-700 w-full'
      >
        {/* Tabs */}
        <div className='flex items-center justify-center pt-7  gap-4'>
          {tabs.map((tab, i) => (
            <button
              onClick={() => {
                setTabValue(i)
                setTableOn(false)
                setValue(null)
              }}
              className={`py-1.5 px-4 rounded-lg ${
                tabValue === i
                  ? 'bg-main-900 text-white'
                  : 'bg-gray-200 text-gray-900'
              }`}
              key={i}
            >
              {tab}
            </button>
          ))}
        </div>
        <div
          className={`flex items-center justify-center flex-wrap gap-3 p-3 mt-3 transition-all ${
            tableOn ? '' : ''
          }`}
        >
          {numOfTables.map((table, i) => (
            <div
              className={`relative flex items-end justify-center w-20 h-20 rounded-lg text-white text-2xl overflow-hidden border-2 border-main-900 ${
                value === table ? 'scale-105' : ''
              }`}
              key={table}
              onClick={() => {
                setValue((prev) => (prev ? null : table))
                setTableOn((prev) => !prev)
              }}
            >
              <img
                src={value === table ? activeTable : tableImg}
                alt='table'
                className='absolute w-full h-full p-1'
              />
              <button
                className={`font-bold ${
                  value === table
                    ? 'text-main-900 dark:text-white text-2xl'
                    : 'text-black dark:text-gray-200 text-lg'
                }`}
              >
                {table}
              </button>
            </div>
          ))}
        </div>
        <div
          className={`fixed max-w-md w-full flex flex-col justify-end dark:bg-gray-900 gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] quick-transition 
          ${tableOn ? 'bottom-0' : '-bottom-[100vh]'}`}
        >
          <div className='flex items-center justify-center'>
            <div className='relative flex items-end justify-center w-32 h-32 rounded-lg text-white text-2xl overflow-hidden'>
              <img
                src={activeTable}
                alt='activeTable'
                className='absolute inset-0 w-full h-full p-1'
              />
              <button className='text-main-900 font-bold text-4xl dark:text-white'>
                {value}
              </button>
            </div>
          </div>
          <div className='flex items-center gap-4 mt-4'>
            <button
              className='py-2 w-full rounded-lg bg-red-400 text-white'
              onClick={() => {
                setTableOn(false)
                setValue(null)
              }}
            >
              إلغاء
            </button>
            <button
              className='py-2 w-full rounded-lg bg-main-900 text-white'
              onClick={confirmOrder}
            >
              تأكيد الطلب
            </button>
          </div>
        </div>
        <ToastContainer
          closeButton={true}
          closeOnClick={true}
          position='bottom-center'
          rtl={true}
        />
      </div>
    </>
  )
}

export default TableNumber
