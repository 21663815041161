/*global google*/

import {
  GoogleMap,
  Marker,
  Autocomplete,
} from '@react-google-maps/api'
import { useForm } from 'react-hook-form'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import usePlaceAutocomplete, {getGeocode, getLatLng, } from 'use-places-autocomplete'
import { BiSearch, BiTargetLock } from 'react-icons/bi'
import { toast } from 'react-toastify'
import marker from '../img/marker2.svg'
import point from '../img/point.png'

const MyGoogleMap = ({ latlng, fixedCenter, setFixedCenter }) => {
  const mapRef = useRef()

  const { register, watch, handleSubmit, setValue: setInputValue } = useForm()

  const {
    value,
    setValue,
  } = usePlaceAutocomplete();
  
  const onSubmit = (data) => {
    console.log(data)
    setValue(data?.search)
  }

  const handleBoundsChanged = () => {
    const centerLat = mapRef.current?.center?.lat() //get map center
    const centerLng = mapRef.current?.center?.lng() //get map center
    setFixedCenter({ lat: centerLat, lng: centerLng })
  }

  // center will be user location if exists, if not Saudi Arabia center
  const center = useMemo(
    () => (latlng?.lat ? latlng : { lat: 23.8859, lng: 45.0792 }), // مركز المملكة العربية السعودية
    [latlng]
  )

  const mapStyles = useMemo(
    () => [
      {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }],
      },
    ],
    []
  )
  const options = useMemo(
    () => ({
      styles: mapStyles,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      gestureHandling: 'greedy',
      zoom: latlng?.lat ? 15 : 4, // تبعيد الخريطة لعرض المملكة كاملة
    }),
    [mapStyles, latlng]
  )
  const onLoad = useCallback((map) => (mapRef.current = map), [])

  useEffect(() => {
    if (!value) return

    const getResults = async () => {
      const results = await getGeocode({ address: value })
      const { lat, lng } = await getLatLng(results[0])

      console.log('results', results)
      console.log('lat', lat)
      console.log('lng', lng)

      mapRef.current.panTo({ lat, lng })
      setInputValue('search', '')
    }

    getResults()
  }, [value, setInputValue])

  const handleTargetClick = () => {
    if (!latlng?.lat) {
      toast.info('قم بتفعيل إذن الموقع ، انتقل إلى الإعدادات > الخصوصية > خدمات الموقع > مواقع سفاري واسمح بالوصول للموقع .')
      // التحرك إلى مركز المملكة عند عدم وجود موقع
      const defaultLocation = { lat: 23.8859, lng: 45.0792 }
      mapRef.current.panTo(defaultLocation)
      setFixedCenter(defaultLocation)
      mapRef.current.setZoom(4)
      return
    }
    
    // التحرك إلى موقع العميل بشكل سلس
    mapRef.current.panTo(latlng)
    setTimeout(() => {
      mapRef.current.setZoom(15)
    }, 900)
    setFixedCenter(latlng)
  }

  return (
    <div className='relative w-full h-full'>
      <GoogleMap
        zoom={4}
        center={center}
        options={options}
        onLoad={onLoad}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        onBoundsChanged={handleBoundsChanged}
      >
        <Marker
          position={fixedCenter}
          icon={{
            url: marker,
            scaledSize: new google.maps.Size(50, 60),
          }}
          zIndex={2}
        />
        <Marker
          position={latlng}
          icon={{
            url: point,
            scaledSize: new google.maps.Size(15, 15),
          }}
        />
        {/* Search */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='absolute shadow-md z-40 top-4 left-4 flex items-center overflow-hidden justify-center bg-white rounded-full text-gray-700 group'
        >
          <Autocomplete onPlaceChanged={() => console.log(watch('search'))}>
            <input
              type='text'
              dir='ltr'
              autoComplete='off'
              {...register('search')}
              className={`w-0 group-hover:w-40 focus:w-40 active:w-40 transition-all outline-none ${
                watch('search') && 'w-40'
              }`}
            />
          </Autocomplete>
          <button
            type='submit'
            className='w-8 h-8 p-1 shadow-md flex items-center justify-center'
          >
            <BiSearch />
          </button>
        </form>

        {/* Target */}
        <div
          className='absolute z-40 top-48 left-4 w-8 h-8 p-1 flex shadow-md items-center justify-center bg-white rounded-full text-gray-700 cursor-pointer hover:bg-[#ebebeb]'
          onClick={handleTargetClick}
          style={{ transition: 'background-color 0.2s ease' }}
        >
          <BiTargetLock className="w-5 h-5" />
        </div>
      </GoogleMap>
    </div>
  )
}

export default MyGoogleMap
