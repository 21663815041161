import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import { IoGiftOutline } from 'react-icons/io5'

const LoyaltyPoints = () => {
  const navigate = useNavigate()
  const points = parseInt(localStorage.getItem('loyaltyPoints') || '0')
  const orders = JSON.parse(localStorage.getItem('orders') || '[]')

  return (
    <div className='fixed inset-0'>
      <div className='w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <IoGiftOutline className='w-8 h-8 text-main-900' />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-center text-main-900 dark:text-white'>
              <span className='inline-block transform translate-y-1 text-brown-400'>
                نقاط الولاء
              </span>
              <span className='font-extrabold text-md text-center text-main-900 dark:text-white overflow-hidden'>
                Loyalty Points
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 rounded-full text-main-900 hover:bg-main-900 hover:text-white dark:text-white transition p-2 cursor-pointer ml-4'
          title='رجوع'
          onClick={() => navigate(-1)}
        />
      </div>

      <div className='fixed max-w-md w-full flex flex-col pt-[70px] gap-3 px-4 bg-white dark:bg-gray-700'>
        <div className='bg-main-900 rounded-lg p-4 text-white text-center'>
          <h2 className='text-2xl font-bold mb-2'>{points}</h2>
          <p className='text-sm'>نقطة</p>
        </div>

        <div className='bg-gray-100 dark:bg-gray-600 rounded-lg p-4'>
          <h3 className='text-lg font-semibold mb-2 text-main-900 dark:text-white'>معلومات نقاط الولاء</h3>
          <ul className='space-y-2 text-gray-600 dark:text-gray-200'>
            <li>• عدد الطلبات: {orders.length}</li>
            <li>• النقاط المكتسبة: {orders.length * 10}</li>
            <li>• مجموع النقاط: {points}</li>
          </ul>
        </div>

        <div className='bg-white dark:bg-gray-800 rounded-lg p-4 shadow-md'>
          <h3 className='text-lg font-semibold mb-4 text-main-900 dark:text-white'>المكافآت المتاحة</h3>
          <div className='space-y-4'>
            <div className='border dark:border-gray-600 rounded-lg p-3'>
              <h4 className='font-semibold text-gray-800 dark:text-white'>خصم 10%</h4>
              <p className='text-sm text-gray-500 dark:text-gray-400'>100 نقطة</p>
              <button
                onClick={() => {
                  if (points >= 100) {
                    const discount = {
                      type: '10%',
                      value: 0.1,
                      code: 'LOYALTY10_' + Date.now(),
                      used: false
                    }
                    localStorage.setItem('loyaltyPoints', (points - 100).toString())
                    localStorage.setItem('activeDiscount', JSON.stringify(discount))
                    alert('تم تفعيل خصم 10% على طلبك القادم!')
                    navigate('/')
                  } else {
                    alert('عدد النقاط غير كافي')
                  }
                }}
                className='mt-2 bg-main-900 text-white px-4 py-2 rounded-full text-sm hover:bg-opacity-90 transition w-full'
              >
                استبدال
              </button>
            </div>
            <div className='border dark:border-gray-600 rounded-lg p-3'>
              <h4 className='font-semibold text-gray-800 dark:text-white'>خصم 25%</h4>
              <p className='text-sm text-gray-500 dark:text-gray-400'>200 نقطة</p>
              <button
                onClick={() => {
                  if (points >= 200) {
                    const discount = {
                      type: '25%',
                      value: 0.25,
                      code: 'LOYALTY25_' + Date.now(),
                      used: false
                    }
                    localStorage.setItem('loyaltyPoints', (points - 200).toString())
                    localStorage.setItem('activeDiscount', JSON.stringify(discount))
                    alert('تم تفعيل خصم 25% على طلبك القادم!')
                    navigate('/')
                  } else {
                    alert('عدد النقاط غير كافي')
                  }
                }}
                className='mt-2 bg-main-900 text-white px-4 py-2 rounded-full text-sm hover:bg-opacity-90 transition w-full'
              >
                استبدال
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoyaltyPoints
