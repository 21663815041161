import { useForm } from 'react-hook-form'
import { IoIosArrowBack } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import { useLockBodyScroll } from '@uidotdev/usehooks'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useState, useEffect } from 'react'

import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import delivery from '../img/delivery.png'
import restaurant from '../img/restaurant.png'
import takeAway from '../img/take-away.png'
import carDelivery from '../img/car-delivery.png'
import { deliveryOptions } from '../hooks/branchesManager'

const Options = ({ setCartOn }) => {
  const navigate = useNavigate()
  useLockBodyScroll()
  const [showError, setShowError] = useState(false)
  const [toastId, setToastId] = useState(null)

  const {
    register,
    handleSubmit,
    watch,
  } = useForm()

  const selectedOption = watch('delivery')

  useEffect(() => {
    if (selectedOption && toastId) {
      toast.dismiss(toastId)
      setShowError(false)
      setToastId(null)
    }
  }, [selectedOption, toastId])

  const onSubmit = (data) => {
    if (!data.delivery) {
      // التحقق من عدم وجود تنبيه حالي قبل إظهار تنبيه جديد
      if (!toast.isActive('error-toast')) {
        toast.error('الرجاء تحديد خيار واحد على الأقل', {
          toastId: 'error-toast',
          onOpen: () => {
            setShowError(true)
            setToastId('error-toast')
          },
          onClose: () => {
            setShowError(false)
            setToastId(null)
          }
        })
      }
      return
    }
    console.log(`Selected type: ${data.delivery} saved`);
    localStorage.setItem('deliveryType', data.delivery);
    if (data.delivery === 'in_restaurant') {
      navigate('/in-restaurant')
    } else if (data.delivery === 'deliver_home') {
      navigate(`/transport`)
    } else if (data.delivery === 'car_delivery') {
      navigate(`/car-delivery`)
    } else if (data.delivery === 'pickup') {
      navigate(`/recieve-from-store`)
    }
  }

  // تحويل خيارات التوصيل إلى مصفوفة من العناصر القابلة للعرض
  const deliveryOptionsMap = {
    in_restaurant: {
      value: 'in_restaurant',
      name: 'قسم محلي',
      description: 'اختر رقم الطاولة',
      image: restaurant,
      className: 'peer-checked:border-emerald-500 peer-checked:bg-emerald-50 dark:peer-checked:bg-emerald-900/20 hover:border-emerald-200',
      enabled: deliveryOptions.find(opt => opt.name === 'إختيار الطاولة')?.enabled,
      order: 1
    },
    deliver_home: {
      value: 'deliver_home',
      name: 'توصيل',
      description: 'توصيل إلى منزلك',
      image: delivery,
      className: 'peer-checked:border-orange-500 peer-checked:bg-orange-50 dark:peer-checked:bg-orange-900/20 hover:border-orange-200',
      enabled: deliveryOptions.find(opt => opt.name === 'توصيل')?.enabled,
      order: 2
    },
    car_delivery: {
      value: 'car_delivery',
      name: 'التسليم للسيارة',
      description: 'استلم طلبك من سيارتك',
      image: carDelivery,
      className: 'peer-checked:border-blue-500 peer-checked:bg-blue-50 dark:peer-checked:bg-blue-900/20 hover:border-blue-200',
      enabled: deliveryOptions.find(opt => opt.name === 'تسليم للسيارة')?.enabled,
      order: 3
    },
    pickup: {
      value: 'pickup',
      name: 'استلام من الفرع',
      description: 'اختر الفرع المناسب لك',
      image: takeAway,
      className: 'peer-checked:border-purple-500 peer-checked:bg-purple-50 dark:peer-checked:bg-purple-900/20 hover:border-purple-200',
      enabled: deliveryOptions.find(opt => opt.name === 'إستلام')?.enabled,
      order: 4
    }
  }

  return (
    <div className='fixed inset-x-0 top-0 z-[401] overflow-y-auto max-w-md md:ml-auto md:mr-0 mx-auto h-full bg-white dark:bg-gray-700 overflow-x-hidden w-full'>
      <div className='w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className='w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className='w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1 text-brown-400'>
                خيارات التسليم
              </span>
              <span className='font-extrabold text-md text-center text-main-900 dark:text-white overflow-hidden'>
                {' '}
                Delivery options
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 rounded-full text-main-900 hover:bg-main-900 hover:text-white dark:text-white transition p-2 cursor-pointer ml-4'
          title='رجوع'
          onClick={() => {
            navigate(-1)
            setCartOn(true)
          }}
        />
      </div>

      <div className='mt-20 px-4 fastAnimate'>
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
          <div className='grid grid-cols-1 gap-4'>
            {Object.values(deliveryOptionsMap)
              .sort((a, b) => a.order - b.order)
              .map((option) =>
                option.enabled ? (
                  <label key={option.value} className='relative'>
                    <input
                      type='radio'
                      value={option.value}
                      {...register('delivery')}
                      className='hidden peer'
                    />
                    <div className={`cursor-pointer p-4 border rounded-lg flex items-center gap-4 
                      dark:bg-gray-800 dark:border-gray-600
                      transition-colors duration-200 
                      ${option.className}
                      ${showError && !selectedOption ? 'border-red-500 dark:border-red-400' : ''}`}>
                      <img src={option.image} alt={option.name} className='w-16 h-16' />
                      <div>
                        <h3 className='text-lg font-semibold text-gray-500 dark:text-white'>{option.name}</h3>
                        <p className='text-gray-600 dark:text-gray-300'>{option.description}</p>
                      </div>
                    </div>
                  </label>
                ) : null
              )}
          </div>
          <button
            className='bg-main-900 font-semibold hover:bg-main-900 transition text-white py-2 w-full rounded-full'
            type='submit'
          >
            التالي
          </button>
        </form>
      </div>
    </div>
  )
}

export default Options
