import React from 'react';

const CardLoader = () => {
  return (
    <div className="w-full relative h-28 rounded-lg grid grid-cols-12 gap-2 bg-gray-100 dark:bg-gray-900 animate-pulse">
      {/* صورة المنتج */}
      <div className="relative w-full rounded-lg col-span-4 sm:col-span-3 flex items-center justify-center">
        <div className="absolute inset-0 w-full h-full p-0.5 rounded-lg bg-gray-200 dark:bg-gray-800" />
      </div>
      
      {/* معلومات المنتج */}
      <div className="w-full relative col-span-8 sm:col-span-9 space-y-1 sm:space-y-2 px-2 flex flex-col justify-between">
        {/* اسم المنتج */}
        <div className="mt-2 h-4 bg-gray-200 dark:bg-gray-800 rounded w-1/2" />
        
        {/* الوصف */}
        <div className="space-y-1">
          <div className="h-3 bg-gray-200 dark:bg-gray-800 rounded w-3/4" />
          <div className="h-3 bg-gray-200 dark:bg-gray-800 rounded w-1/2" />
        </div>
        
        {/* السعر والسعرات */}
        <div className="flex justify-between py-2 items-center w-full">
          <div className="h-3 bg-gray-200 dark:bg-gray-800 rounded w-20" />
          <div className="h-4 bg-gray-200 dark:bg-gray-800 rounded w-16 mr-auto" />
        </div>
      </div>
    </div>
  );
}

export default CardLoader;
