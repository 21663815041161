import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5'
import { IoMdAddCircle, IoIosArrowBack } from 'react-icons/io'
import { useGlobalContext } from '../context'
import useDarkMode from '../hooks/useDarkMode'
import { useRef, useState, useEffect } from 'react'
import { useLockBodyScroll } from '@uidotdev/usehooks'
import CountUp from 'react-countup'

const SingleItem = ({ singleItem, setModalOn }) => {
  useLockBodyScroll()
  useDarkMode()
  const [quantity, setQuantity] = useState(1)
  const { cartData, setCartData } = useGlobalContext()
  const [selectedExtras, setSelectedExtras] = useState([])
  const [selectedEdits, setSelectedEdits] = useState([])
  const [price, setPrice] = useState(singleItem.price)
  const [size, setSize] = useState(
    singleItem.size
      ? { name: 'small', value: singleItem.size.small }
      : { name: '', value: singleItem.price }
  )
  const [, setExtra] = useState(
    singleItem.extras
      ? { name: singleItem.extras.name, price: singleItem.extras.price }
      : { name: '', price: 0 }
  )
  const [, setEdit] = useState(singleItem.edits ? singleItem.edits[0] : '')

  const quntplsRef = useRef(null)
  const add = () => {
    setQuantity((prev) => prev + 1)
    quntplsRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  const quntminsRef = useRef(null)
  const remove = () => {
    if (quantity === 1) return
    setQuantity((prev) => prev - 1)
    quntminsRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  const addToCart = () => {
    const newCartItem = {
      name: singleItem.title,
      id: singleItem.id,
      img: singleItem.image,
      description: singleItem.description,
      quantity,
      price: price * quantity,
      size,
      extra: selectedExtras,
      edits: selectedEdits,
    }

    const existingItemIndex = cartData.findIndex(
      (item) =>
        item.id === newCartItem.id &&
        item.size.name === newCartItem.size.name &&
        item.extra
          .map((e) => e.name)
          .sort()
          .toString() ===
        newCartItem.extra
          .map((e) => e.name)
          .sort()
          .toString() &&
        item.edits
          .map((e) => e)
          .sort()
          .toString() ===
        newCartItem.edits
          .map((e) => e)
          .sort()
          .toString()
    )
    if (existingItemIndex !== -1) {
      cartData[existingItemIndex].quantity += newCartItem.quantity
      cartData[existingItemIndex].price += newCartItem.price
      setCartData([...cartData])
    } else {
      setCartData([...cartData, newCartItem])
    }
    setQuantity(1)
    setModalOn(false)
  }
  const sizeRef = useRef(null)
  const onSizeChange = (e) => {
    setSize({ name: e.target.id, value: e.target.value })
    sizeRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
    const size = singleItem.size[e.target.id]
    const selectedExtrasPrice = selectedExtras.reduce(
      (acc, extra) => acc + extra.price,
      0
    )
    setPrice(size + selectedExtrasPrice)
  }
  const extrasRef = useRef(null)
  const onExtraChange = (e) => {
    extrasRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
    let selectedExtra = {}
    if (singleItem.extras) {
      selectedExtra = singleItem.extras.find(
        (extra) => extra.name === e.target.id
      )
    }
    if (!selectedExtra) return
    setExtra(selectedExtra)
    let extraPrice = 0
    if (e.target.checked) {
      extraPrice = selectedExtra.price
      setSelectedExtras([...selectedExtras, selectedExtra])
    } else {
      extraPrice = -selectedExtra.price
      setSelectedExtras(
        selectedExtras.filter((extra) => extra.name !== selectedExtra.name)
      )
    }
    setPrice((prev) => prev + extraPrice)
  }
  const EditsRef = useRef(null)
  const onEditChange = (e) => {
    EditsRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
    let selectedEdit = {}
    if (singleItem.edits) {
      selectedEdit = singleItem.edits.find((edit) => edit === e.target.id)
    }
    if (!selectedEdit) return
    setEdit(selectedEdit)
    if (e.target.checked) {
      setSelectedEdits([...selectedEdits, selectedEdit])
    } else {
      setSelectedEdits(selectedEdits.filter((edit) => edit !== selectedEdit))
    }
  }

  const [prevPrice, setPrevPrice] = useState(price * quantity)
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    if (prevPrice !== price * quantity) {
      setIsAnimating(true)
      setPrevPrice(price * quantity)
      const timer = setTimeout(() => setIsAnimating(false), 500)
      return () => clearTimeout(timer)
    }
  }, [price, quantity, prevPrice])

  useEffect(() => {
    const style = document.createElement('style')
    style.textContent = `
      @keyframes pulse {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }
      .pulse-animation {
        animation: pulse 0.5s ease-in-out;
      }
    `
    document.head.appendChild(style)
    return () => document.head.removeChild(style)
  }, [])

  return (
    <div
      dir='rtl'
      className='fixed overflow-y-auto inset-x-0 max-w-md md:ml-auto md:mr-0 mx-auto h-full bg-white dark:bg-gray-700 overflow-x-hidden w-full z-[401] fastAnimate'
    >
      <div className='relative h-full'>
        <div className='z-50 fixed w-full bg-white max-w-md mx-auto h-16 top-auto shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
          <div className='col-span-9 grid justify-start items-center'>
            <div
              onClick={() => setModalOn(false)}
              className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
            >
              <img
                src={logo_light}
                alt='main logo'
                className=' w-[56px] h-[57px] dark:hidden'
              />
              <img
                src={logo_dark}
                alt='main logo'
                className=' w-[56px] h-[57px] hidden dark:block'
              />
              <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
                <span className='inline-block transform translate-y-1 text-brown-400'>
                  تخصيص الطلب
                </span>
                <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                  {' '}
                  Customize Order
                </span>
              </h1>
            </div>
          </div>
          <IoIosArrowBack
            className='w-10 h-10 ml-4 rounded-full text-main-900 hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
            title='رجوع'
            onClick={() => setModalOn(false)}
          />
        </div>
        <div className='custom-content-padding p-4 rounded-lg bg-white dark:bg-gray-700 flex flex-col w-full pt-16'>
          <div className='flex flex-col justify-center text-center gap-4 mb-8 mt-5 dark:text-white'>
            <img
              src={singleItem.image}
              alt={singleItem.title}
              style={{
                width: '100%', /* تملأ عرض الحاوية بالكامل */
                height: '275px', /* الحفاظ على نسب العرض والارتفاع الطبيعية */
                objectFit: 'cover', /* تضمن عدم تشويه الصورة */
                borderRadius: '15px', /* حواف منحنية إذا كنت تريد */
                minWidth: '100%',
                backgroundSize: 'cover',
                backgroundPosition: '50% center',
                backgroundRepeat: 'no-repeat',
              }}
            />
            <h2 className='text-2xl text-main-900 dark:text-white font-semibold'>
              {singleItem.title}
            </h2>
            <p className='text-gray-700 dark:text-gray-200 text-base'>
              {singleItem.description}
            </p>
            <div className='flex flex-col gap-4'>
              <h4 className='text-lg text-main-900 bg-[#f3f4f6] py-1 rounded-full w-full text-center dark:bg-gray-900 dark:text-white'>
                الكمية
              </h4>
              <div
                ref={quntminsRef}
                className='flex singleItems-center justify-center gap-4 select-none'
              >
                <IoRemoveCircleOutline
                  className={`text-4xl ${quantity === 1
                      ? 'text-[#0000004d] dark:text-[#9ca3af]'
                      : 'text-main-900'
                    } cursor-pointer selected-none`}
                  onClick={remove}
                />
                <span ref={quntplsRef} className='text-xl'>
                  {quantity}
                </span>
                <IoAddCircleOutline
                  className='text-4xl text-main-900 cursor-pointer'
                  onClick={add}
                />
              </div>
            </div>
            {singleItem.size &&
              (singleItem.size.small > 0 ||
                singleItem.size.medium > 0 ||
                singleItem.size.large > 0) ? (
              <div ref={sizeRef} className='flex flex-col gap-4'>
                <h4 className='text-lg text-main-900 bg-[#f3f4f6] py-1 rounded-full w-full text-center dark:bg-gray-900 dark:text-white'>
                  الحجم
                </h4>
                <div className='flex singleItems-center justify-center gap-6'>
                  {singleItem.size.small > 0 ? (
                    <div>
                      <input
                        type='radio'
                        name='size'
                        id='small'
                        className='hidden peer'
                        defaultChecked
                        value={singleItem.size ? singleItem.size.small : 'small'}
                        onChange={(e) => onSizeChange(e)}
                      />
                      <label
                        htmlFor='small'
                        className='py-2 px-6 bg-[#f3f4f6] rounded-full text-main-900 peer-checked:text-white peer-checked:bg-main-900 dark:bg-gray-900 dark:text-white'
                      >
                        صغير
                      </label>
                    </div>
                  ) : null}
                  {singleItem.size.medium > 0 ? (
                    <div>
                      <input
                        type='radio'
                        name='size'
                        id='medium'
                        className='hidden peer'
                        value={
                          singleItem.size ? singleItem.size.medium : 'medium'
                        }
                        onChange={(e) => onSizeChange(e)}
                      />
                      <label
                        htmlFor='medium'
                        className='py-2 px-6 bg-[#f3f4f6] rounded-full text-main-900 peer-checked:text-white peer-checked:bg-main-900 dark:bg-gray-900 dark:text-white'
                      >
                        وسط
                      </label>
                    </div>
                  ) : null}
                  {singleItem.size.large > 0 ? (
                    <div>
                      <input
                        type='radio'
                        name='size'
                        id='large'
                        className='hidden peer'
                        value={singleItem.size ? singleItem.size.large : 'large'}
                        onChange={(e) => onSizeChange(e)}
                      />
                      <label
                        htmlFor='large'
                        className='py-2 px-6 bg-[#f3f4f6] rounded-full text-main-900 peer-checked:text-white peer-checked:bg-main-900 dark:bg-gray-900 dark:text-white'
                      >
                        كبير
                      </label>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {singleItem.extras ? (
              <div ref={extrasRef} className='flex flex-col gap-4'>
                <h4 className='text-lg text-main-900 bg-[#f3f4f6] py-1 rounded-full w-full text-center dark:bg-gray-900 dark:text-white'>
                  الاضافات
                </h4>
                <div className='flex singleItems-center flex-wrap justify-center gap-x-1 gap-y-5'>
                  {singleItem.extras.map((extra, i) => (
                    <div key={i}>
                      <input
                        type='checkbox'
                        name={extra.price}
                        id={extra.name}
                        value={extra.name}
                        className='hidden peer'
                        onChange={(e) => onExtraChange(e)}
                      />
                      <label
                        htmlFor={extra.name}
                        className='py-2 px-4 bg-[#f3f4f6] rounded-full text-main-900 peer-checked:text-white peer-checked:bg-main-900 dark:bg-gray-900 dark:text-white whitespace-nowrap'
                      >
                        {extra.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            {singleItem.edits ? (
              <div ref={EditsRef} className='flex flex-col gap-4'>
                <h4 className='text-lg text-main-900 bg-[#f3f4f6] py-1 rounded-full w-full text-center dark:bg-gray-900 dark:text-white'>
                  التعديلات
                </h4>
                <div className='flex singleItems-center flex-wrap justify-center gap-x-1 gap-y-5'>
                  {singleItem.edits.map((edit, i) => (
                    <div key={i}>
                      <input
                        type='checkbox'
                        name={edit}
                        id={edit}
                        value={edit}
                        className='hidden peer'
                        onChange={(e) => onEditChange(e)}
                      />
                      <label
                        htmlFor={edit}
                        className='py-2 px-4 bg-[#f3f4f6] rounded-full text-main-900 peer-checked:text-white peer-checked:bg-main-900 dark:bg-gray-900 dark:text-white whitespace-nowrap'
                      >
                        {edit}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <div className='sticky bottom-0 bg-white dark:bg-gray-700 py-4 mt-auto'>
            <button
              className='font-semibold flex items-center justify-center bg-main-900 text-white rounded-full gap-2 border-2 border-main-900 py-2 px-4 w-full transition-transform hover:scale-[0.98] active:scale-[0.95]'
              onClick={addToCart}
            >
              <span className='flex items-center gap-2'>
                <IoMdAddCircle className={`text-2xl text-white ${
                  isAnimating ? 'pulse-animation' : ''
                }`} /> إضافة
              </span>
              <span className='text-md font-semibold whitespace-nowrap'>
                <CountUp
                  end={price * quantity}
                  duration={0.5}
                  separator=","
                  decimal="."
                  decimals={(price * quantity) % 1 === 0 ? 0 : 2}
                  preserveValue={true}
                /> ريال
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleItem