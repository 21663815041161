import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsCheck2Circle } from 'react-icons/bs';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { BsClockHistory } from 'react-icons/bs';
import { FaMotorcycle, FaStore, FaUtensils, FaCar } from 'react-icons/fa';
import { TbReceipt } from 'react-icons/tb';
import moment from 'moment';
import 'moment/locale/ar-sa';

const OrderHistory = () => {
  const navigate = useNavigate();
  const orders = JSON.parse(localStorage.getItem('orders') || '[]');
  const restaurantName = document.title.split(' | ')[0];

  // تنسيق التاريخ
  const formatDate = (dateString) => {
    if (!dateString) return 'تاريخ غير معروف';
    try {
      moment.locale('en'); 
      const formattedDate = moment(dateString).format('DD/MM/YYYY - hh:mm A');
      return formattedDate.replace('AM', 'ص').replace('PM', 'م');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'تاريخ غير معروف';
    }
  };

  // ترجمة الحجم إلى العربية
  const translateSize = (size) => {
    const sizeMap = {
      'small': 'صغير',
      'medium': 'وسط',
      'large': 'كبير'
    };
    return sizeMap[size] || size;
  };

  return (
    <div className='flex flex-col h-screen overflow-y-auto'>
      <div className='w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white  flex items-center cursor-pointer gap-2'
          >
            <BsClockHistory className='w-8 h-8 text-main-900' />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-main-900 dark:text-white'>
              <span className='inline-block transform translate-y-1 text-brown-400'>
                الطلبات السابقة
              </span>
              <span className='font-extrabold text-md text-main-900 dark:text-white '>
                Order History
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 rounded-full text-main-900 hover:bg-main-900 hover:text-white dark:text-white transition p-2 cursor-pointer ml-4'
          title='رجوع'
          onClick={() => navigate(-1)}
        />
      </div>

      <div className='relative max-w-md w-full flex flex-col pt-[70px] gap-3 px-4 bg-white dark:bg-gray-700'>
        {/* محتوى الطلبات */}
        <div className="p-4 space-y-4">
          {orders.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-8 mt-15">
              <div className="bg-main-900 w-[110%] p-4 text-center rounded-lg">
                <div className="text-white mb-4">
                  <TbReceipt className="w-16 h-16 mx-auto" />
                </div>
                <h2 className="text-white text-2xl font-bold mb-2">لا طلبات بعد</h2>
                <p className="text-white mb-5">لا يوجد لديك أي طلبات من {restaurantName}</p>
                <button 
                  onClick={() => navigate('/')}
                  className="bg-white hover:bg-gray-100 text-main-900 px-24 py-3 rounded-full text-lg font-semibold transition-colors"
                >
                  اطلب الآن
                </button>
              </div>
            </div>
          ) : (
            orders.map((order, index) => (
              <div key={index} className="space-y-4">
                {/* معلومات الطلب */}
                <div className="order-card bg-white rounded-lg shadow-md p-4 mb-4">
                  {/* رأس البطاقة */}
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center gap-1">
                      <span className="text-gray-600">طلب</span>
                      <div className="bg-[#4A6670] text-white min-w-[28px] h-7 px-2 rounded-full flex items-center justify-center">
                        <span className="font-semibold">{order.orderId}</span>
                      </div>
                    </div>
                    <span className="text-sm text-gray-600">{formatDate(order.date)}</span>
                  </div>

                  {/* تفاصيل المنتجات */}
                  {Array.isArray(order.items) && order.items.map((item, itemIndex) => (
                    <div key={itemIndex} className="bg-gray-50 rounded-lg mb-2 last:mb-0">
                      <div className="relative">
                        {/* صورة المنتج */}
                        <div className="relative w-full h-40">
                          <img
                            src={item.image}
                            alt={item.name}
                            className="w-full h-full object-cover rounded-t-lg"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '../../img/add-to-basket.png';
                            }}
                          />
                        </div>

                        {/* تفاصيل المنتج */}
                        <div className="p-3">
                          <div className="flex justify-between items-start mb-2">
                            <h3 className="text-[#4A6670] text-lg font-semibold">
                              {item.name}
                            </h3>
                            <div className="flex items-center gap-1 text-green-500">
                              <BsCheck2Circle className="w-5 h-5" />
                              <span className="text-sm">مكتمل</span>
                            </div>
                          </div>

                          <div className="space-y-1 text-sm text-gray-600">
                            <div className="flex justify-between items-center">
                              <p>الحجم : {translateSize(item.size)}</p>
                              <p>الكمية : {item.quantity || 1}</p>
                            </div>
                            {item.additions && item.additions.length > 0 && (
                              <div className="bg-[#4A6670] text-white p-2 rounded">
                                <p>الإضافات : {item.additions.join(' ، ')}</p>
                              </div>
                            )}
                            {item.modifications && item.modifications.length > 0 && (
                              <div className="bg-gray-200 text-gray-700 p-2 rounded mt-1">
                                <p>التعديلات : {item.modifications.join(' ، ')}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* تذييل البطاقة */}
                  <div className="mt-4 pt-3 border-t border-gray-200">
                    <div className="flex flex-col gap-2">
                      <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                          {/* نوع التوصيل */}
                          <div className="flex items-center gap-2 px-3 py-1.5 rounded-full text-sm font-medium">
                            {order.deliveryMethod === 'توصيل' && (
                              <div className="flex items-center gap-2 bg-orange-50 text-orange-600 px-3 py-1.5 rounded-full">
                                <FaMotorcycle className="w-4 h-4" />
                                <span>توصيل للمنزل</span>
                              </div>
                            )}
                            {order.deliveryMethod === 'محلي' && (
                              <div className="flex items-center gap-2 bg-emerald-50 text-emerald-600 px-3 py-1.5 rounded-full">
                                <FaUtensils className="w-4 h-4" />
                                <span>محلي</span>
                              </div>
                            )}
                            {order.deliveryMethod === 'استلام من الفرع' && (
                              <div className="flex items-center gap-2 bg-purple-50 text-purple-600 px-3 py-1.5 rounded-full">
                                <FaStore className="w-4 h-4" />
                                <span>استلام من الفرع</span>
                              </div>
                            )}
                            {order.deliveryMethod === 'تسليم للسيارة' && (
                              <div className="flex items-center gap-2 bg-blue-50 text-blue-600 px-3 py-1.5 rounded-full">
                                <FaCar className="w-4 h-4" />
                                <span>تسليم للسيارة</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-[#4A6670] font-bold">
                          الإجمالي: {order.total} ريال
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
