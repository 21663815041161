import { FiX } from "react-icons/fi"

const SearchLabel = ({ filteredItems, searchValue, close }) => {
  return (
    <div className="flex relative justify-between items-center m-4 px-3 py-2 rounded-lg bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-100">
      <div className="flex flex-col gap-2">
        <div className="inline-block">
          <h2 className="text-xl text-[#012070] dark:text-[#012070] font-bold border-b border-[#012070]/10 dark:border-[#012070]/10 pb-2 inline-block">
            {searchValue}
          </h2>
        </div>
        <div className="flex items-center gap-2">
          <span className="px-2 py-0.5 rounded-full bg-[#0120701a] text-sm font-semibold text-[#012070] dark:text-[#012070]">
            {filteredItems.length}
          </span>
          <span className="text-sm text-[#012070] dark:text-[#012070]">
            من نتائج البحث
          </span>
        </div>
      </div>
      <FiX
        className="w-6 absolute -top-2 -left-2 h-6 p-0.5 rounded-full bg-red-600 text-gray-50 hover:bg-opacity-100 opacity-80 dark:bg-red-600 dark:text-gray-50 block transform hover:rotate-180 cursor-pointer transition duration-300 ease"
        onClick={close}
      />
    </div>
  )
}

export default SearchLabel
