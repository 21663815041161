import { useEffect, useRef, useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Items from './components/Items';
import InstallPrompt from './components/InstallPrompt';
import PageLayout from './components/PageLayout';
import logo from './img/logo.png';
import bg from './img/bg.png';
import Delivery from './pages/Delivery';
import { data as categories } from './data';
import SingleItem from './components/SingleItem';
import CartPage from './components/CartPage';
import { ToastContainer } from 'react-toastify';
import useDarkMode from './hooks/useDarkMode';
import Options from './pages/Options';
import TableNumber from './components/TableNumber';
import ReciveFromStore from './components/ReciveFromStore';
import Transport from './components/Transport';
import SideNav from './components/SideNav';
import OrderHistory from './components/OrderHistory';
import LoyaltyPoints from './components/LoyaltyPoints';
import Profile from './components/Profile';
import Addresses from './components/Addresses';
import CarDelivery from './pages/CarDelivery';
import GoogleMapsProvider from './components/GoogleMapsProvider';

function App() {
  const inputRef = useRef();

  const [sideNav, setSideNav] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  // إضافة وظيفة تشغيل الصوت
  const playSound = (soundName) => {
    const audio = new Audio(`/sounds/${soundName}.mp3`);
    audio.play();
  };

  // تعديل وظيفة تغيير حالة القائمة الجانبية
  const toggleSideNav = (value) => {
    setSideNav(value);
    // تشغيل الصوت المناسب
    if (value) {
      playSound('open');
    } else {
      playSound('close');
    }
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [searchValue, setSearchValue] = useState(inputRef.current?.value || '');
  const [searchQuery, setSearchQuery] = useState('');
  const [swiper, setSwiper] = useState();
  const [quantity, setQuantity] = useState(1);

  const [singleItem, setSingleItem] = useState({});
  const [itemOn, setItemOn] = useState(false);
  const [cartOn, setCartOn] = useState(false);

  const [isVoiceSearch, setIsVoiceSearch] = useState(false);

  const { isDarkMode } = useDarkMode();

  const handleSearch = useCallback((e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
      const query = e.target[0].value;
      setSearchQuery(query);
      setSearchValue(query);
      if (query.length > 0) {
        setIsSubmitted(true);
        const items = categories
          .flatMap((c) => c.items)
          .filter((i) => i.title.includes(query));

        setFilteredItems(items);
        e.target[0].blur();
        document.documentElement.scrollTop = 0;
      }
    } else {
      if (searchQuery.length > 0) {
        setIsSubmitted(true);
        setSearchValue(searchQuery);
        const items = categories
          .flatMap((c) => c.items)
          .filter((i) => i.title.includes(searchQuery));

        setFilteredItems(items);
        setSearchQuery('');
        document.documentElement.scrollTop = 0;
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    // تحديث الجسم (body) حسب حالة الـ sideNav
    document.body.style.overflow = sideNav ? 'hidden' : 'visible';
    // إذا تم تفعيل البحث الصوتي والـ searchQuery موجود، قم بتشغيل البحث
    if (isVoiceSearch && searchQuery.length > 0) {
      handleSearch();
      setIsVoiceSearch(false);
    }
  }, [sideNav, searchQuery, isVoiceSearch, handleSearch]);

  const close = () => {
    setIsSubmitted(false);
    setFilteredItems([]);
  };

  const pagination = {
    el: '.my-custom-pagination',
    clickable: true,
    renderBullet: (index, className) => {
      return `
        <span class="${className}">
          <span className="text-xs whitespace-nowrap font-semibold">
            ${categories[index]?.title}
          </span>
          <Image
            src=${categories[index]?.image}
            alt=""
            width="24"
            height="24"
            priority
            className='z-10'
          />
        </span>
      `;
    },
  };

  return (
    <div className="App">
      <InstallPrompt />
      <GoogleMapsProvider>
        <Router>
          <div className="flex hide-scrollbar">
            <div
              className="relative h-auto max-w-md md:ml-auto md:mr-0 mx-auto transition duration-100 dark:bg-gray-700 hide-scrollbar w-full"
              dir="rtl"
            >
              <Routes>
                <Route
                  element={
                    <PageLayout
                      setSideNav={toggleSideNav}
                      handleSearch={handleSearch}
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      isSubmitted={isSubmitted}
                      filteredItems={filteredItems}
                      searchValue={searchValue}
                      close={close}
                      sideNav={sideNav}
                      swiper={swiper}
                      setModalOn={setCartOn}
                    />
                  }
                >
                  <Route
                    path="/"
                    element={
                      <Items
                        pagination={pagination}
                        close={close}
                        isSubmitted={isSubmitted}
                        filteredItems={filteredItems}
                        swiper={swiper}
                        setSwiper={setSwiper}
                        setModalOn={setItemOn}
                        modalOn={itemOn}
                        singleItem={singleItem}
                        setSingleItem={setSingleItem}
                        cartOn={cartOn}
                      />
                    }
                  />
                </Route>
                <Route
                  element={
                    <SideNav
                      sideNav={sideNav}
                      setSideNav={toggleSideNav}
                      swiper={swiper}
                    />
                  }
                />
                <Route path="/profile" element={<Profile />} />
                <Route path="/delivery" element={<Delivery close={close} />} />
                <Route path="/options" element={<Options setCartOn={setCartOn} />} />
                <Route path="/in-restaurant" element={<TableNumber />} />
                <Route path="/recieve-from-store" element={<ReciveFromStore />} />
                <Route path="/transport" element={<Transport />} />
                <Route path="/car-delivery" element={<CarDelivery />} />
                <Route path="/order-history" element={<OrderHistory />} />
                <Route path="/loyalty-points" element={<LoyaltyPoints />} />
                <Route path="/addresses" element={<Addresses />} />
              </Routes>
            </div>
            <div
              className="hidden md:block h-screen w-[calc(100%-448px)] bg-no-repeat bg-cover fixed"
              style={{ backgroundImage: `url(${bg})` }}
            >
              <div className="bg-black/50 w-full h-full md:flex items-center justify-center">
                <div className="h-64 w-64 p-8 bg-white rounded-full">
                  <img src={logo} alt="logo" />
                </div>
              </div>
            </div>
            {itemOn && singleItem?.title && (
              <SingleItem singleItem={singleItem} setModalOn={setItemOn} />
            )}
            {cartOn && (
              <CartPage
                singleItem={singleItem}
                setModalOn={setCartOn}
                quantity={quantity}
                setQuantity={setQuantity}
                setItemOn={setItemOn}
                setSingleItem={setSingleItem}
              />
            )}
          </div>
        </Router>
      </GoogleMapsProvider>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        rtl={true}
        pauseOnFocusLoss={true}
        pauseOnHover={false}
        draggable
        draggableDirection="up"
        draggablePercent={60}
        theme={isDarkMode ? 'dark' : 'light'}
      />
    </div>
  );
}

export default App;
