import React, { useState, useEffect } from 'react';
import { IoCloseOutline, IoDownloadOutline, IoShareOutline } from 'react-icons/io5';
import { MdOutlineAddBox } from "react-icons/md";
import { installSettings, siteSettings } from '../data/settings';

const InstallPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isChrome = /Chrome/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent);

  useEffect(() => {
    const hasSeenPrompt = localStorage.getItem('hasSeenInstallPrompt');
    
    if (!hasSeenPrompt && installSettings.enabled) {
      if (isIOS && isSafari) {
        setShowPrompt(true);
      } else {
        const handler = async (e) => {
          // نخزن الحدث بدون منعه
          setDeferredPrompt(e);
          setShowPrompt(true);
        };

        window.addEventListener('beforeinstallprompt', handler);
        return () => window.removeEventListener('beforeinstallprompt', handler);
      }
    }
  }, [isIOS, isSafari]);

  useEffect(() => {
    const themeColorMeta = document.querySelector('meta[name="theme-color"]');
    if (themeColorMeta) {
      if (showPrompt) {
        themeColorMeta.setAttribute('content', '#374151');
      } else {
        const isDarkMode = document.documentElement.classList.contains('dark');
        themeColorMeta.setAttribute('content', isDarkMode ? siteSettings.themeColor.dark : siteSettings.themeColor.light);
      }
    }
  }, [showPrompt]);

  const handleClose = () => {
    setIsClosing(true);
    localStorage.setItem('hasSeenInstallPrompt', 'true');
    setTimeout(() => {
      setShowPrompt(false);
      setIsClosing(false);
    }, 200);
  };

  const onInstall = async () => {
    if (isIOS && isSafari) {
      handleClose();
    } else if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        localStorage.setItem('hasSeenInstallPrompt', 'true');
      }
      setDeferredPrompt(null);
      handleClose();
    }
  };

  if (!showPrompt) return null;

  return (
    <>
      {/* خلفية معتمة تغطي كامل الشاشة */}
      <div 
        className={`fixed inset-0 z-[59] install-backdrop ${isClosing ? 'closing' : ''}`}
        style={{
          backdropFilter: 'blur(3px) saturate(80%)',
          WebkitBackdropFilter: 'blur(3px) saturate(80%)'
        }}
      />
      
      {/* نافذة التثبيت */}
      <div className={`fixed top-1/2 left-1/2 w-[90%] md:w-96 bg-white dark:bg-gray-800 rounded-2xl shadow-2xl p-4 z-[65] install-popup ${isClosing ? 'closing' : ''}`} dir="rtl">
        <div className="flex items-start">
          <div className="flex-1 text-right">
            <h3 className="text-xl font-semibold text-[#012070] dark:text-white mb-3">
              {installSettings.title}
            </h3>
            {isIOS && isSafari ? (
              <div className="text-gray-600 dark:text-gray-300 text-sm leading-relaxed">
                <p className="mr-2">{installSettings.safari.title}</p>
                <ol className="steps-list">
                  {installSettings.safari.steps.map((step, index) => (
                    <li key={index}>
                      <div className="step-content">
                        <span>{step}</span>
                        {index === 0 && (
                          <IoShareOutline className="w-5 h-5 text-gray-600 dark:text-gray-300" />
                        )}
                        {index === 1 && (
                          <MdOutlineAddBox className="w-5 h-5 text-gray-600 dark:text-gray-300" />
                        )}
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            ) : (
              <p className="text-gray-600 dark:text-gray-300 text-sm leading-relaxed">
                {isChrome ? installSettings.chrome.description : installSettings.description}
              </p>
            )}
          </div>
          <button
            onClick={handleClose}
            className="text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-white transition-colors mr-4"
          >
            <IoCloseOutline className="w-7 h-7" />
          </button>
        </div>
        
        <div className="mt-6 flex gap-4">
          {isIOS && isSafari ? (
            <div className="flex gap-4 w-full" dir="ltr">
              <button
                onClick={onInstall}
                className="flex-1 bg-main-900 text-white px-6 py-3 rounded-xl flex items-center justify-center gap-2 hover:bg-main-800 transition-colors"
              >
                {installSettings.buttons.understood}
              </button>
              <button
                onClick={handleClose}
                className="flex-1 border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 px-6 py-3 rounded-xl hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
              >
                {installSettings.buttons.later}
              </button>
            </div>
          ) : (
            <div className="flex gap-4 w-full" dir="ltr">
              <button
                onClick={onInstall}
                className="flex-1 bg-main-900 text-white px-6 py-3 rounded-xl flex items-center justify-center gap-2 hover:bg-main-800 transition-colors"
              >
                <IoDownloadOutline className="w-5 h-5" />
                {installSettings.buttons.install}
              </button>
              <button
                onClick={handleClose}
                className="flex-1 border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 px-6 py-3 rounded-xl hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
              >
                {installSettings.buttons.later}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InstallPrompt;
