import ReciveFromStore from '../components/ReciveFromStore'
import TableNumber from '../components/TableNumber'
import Transport from '../components/Transport'

// Hook لمراقبة حالة جميع الفروع
import { useState, useEffect, useCallback } from 'react';

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const restaurants = [
  {
    id: 1,
    name: 'فرع السويدي',
    addrress: 'شارع السويدي العام, حي السويدي, الرياض 12791',
    coords: { lat: 24.5954, lng: 46.6572 },
    services: ['delivery', 'pickup', 'car-pickup'],
    // فرع يعمل بأوقات مختلفة في كل يوم
    open24h: false,
    temporaryClosed: {
      isTemporaryClosed: false,
      date: null,     // التاريخ (YYYY-MM-DD) - اختياري، إذا كان null سيطبق على اليوم الحالي
      open: null,     // وقت بداية الإغلاق المؤقت
      close: null,    // وقت نهاية الإغلاق المؤقت
      reason: 'صيانة دورية'      // سبب الإغلاق المؤقت
    },
    workingHours: {
      sunday: { open: '14:00', close: '02:00', is24h: false },    // يعمل من الظهر حتى ما بعد منتصف الليل
      monday: { open: '00:00', close: '00:00', is24h: false },    // مغلق
      tuesday: { open: '18:00', close: '23:59', is24h: false },   // يعمل مساءً فقط
      wednesday: { open: '00:00', close: '19:10', is24h: false }, // يعمل فقط في الساعات الأولى من اليوم
      thursday: { open: '12:00', close: '12:00', is24h: true },   // مفتوح 24 ساعة
      friday: { open: '16:00', close: '03:00', is24h: false },    // يعمل من العصر حتى الفجر
      saturday: { open: '00:00', close: '02:52', is24h: false }   // يعمل في المساء حتى الفجر
    },
    get displayHours() {
      return this.open24h ? '24 ساعة' : this.workingHours[days[new Date().getDay()]].is24h ? '24 ساعة' :
        `${formatTime(this.workingHours[days[new Date().getDay()]].open)} - ${formatTime(this.workingHours[days[new Date().getDay()]].close)}`;
    }
  },
  {
    id: 2,
    name: 'فرع بيتي',
    addrress: 'رابغ, طويق, الرياض 14921',
    coords: { lat: 24.5758866, lng: 46.5917672 },
    services: ['delivery', 'pickup', 'car-pickup'],
    // فرع يعمل بنظام مناوبات
    open24h: false,
    temporaryClosed: {
      isTemporaryClosed: false,
      date: null,     // التاريخ (YYYY-MM-DD) - اختياري، إذا كان null سيطبق على اليوم الحالي
      open: null,     // وقت بداية الإغلاق المؤقت
      close: null,    // وقت نهاية الإغلاق المؤقت
      reason: ''      // سبب الإغلاق المؤقت
    },
    workingHours: {
      sunday: { open: '00:00', close: '16:00', is24h: false },    // الفترة الصباحية
      monday: { open: '16:00', close: '04:00', is24h: false },    // الفترة المسائية
      tuesday: { open: '08:46', close: '08:47', is24h: false },   // الفترة الصباحية
      wednesday: { open: '10:07', close: '10:07', is24h: false }, // الفترة المسائية
      thursday: { open: '07:00', close: '16:00', is24h: false },  // الفترة الصباحية
      friday: { open: '00:00', close: '04:00', is24h: false },    // الفترة المسائية
      saturday: { open: '12:00', close: '23:00', is24h: false }   
    },
    get displayHours() {
      return this.open24h ? '24 ساعة' : this.workingHours[days[new Date().getDay()]].is24h ? '24 ساعة' :
        `${formatTime(this.workingHours[days[new Date().getDay()]].open)} - ${formatTime(this.workingHours[days[new Date().getDay()]].close)}`;
    }
  },
  {
    id: 3,
    name: 'فرع العريجاء',
    addrress: 'العريجاء الرياض',
    coords: { lat: 24.5907322, lng: 46.579294 },
    services: ['delivery', 'pickup', 'car-pickup'],
    // فرع يعمل بنظام الأيام المفتوحة والمغلقة
    open24h: false,
    temporaryClosed: {
      isTemporaryClosed: false,
      date: null,     // التاريخ (YYYY-MM-DD) - اختياري، إذا كان null سيطبق على اليوم الحالي
      open: null,     // وقت بداية الإغلاق المؤقت
      close: null,    // وقت نهاية الإغلاق المؤقت
      reason: ''      // سبب الإغلاق المؤقت
    },
    workingHours: {
      sunday: { open: '00:00', close: '05:00', is24h: false },    
      monday: { open: '00:00', close: '06:10', is24h: false },     // مفتوح 24 ساعة
      tuesday: { open: '00:00', close: '00:00', is24h: false },   // مغلق
      wednesday: { open: '00:00', close: '23:59', is24h: true },  // مفتوح 24 ساعة
      thursday: { open: '00:00', close: '00:00', is24h: false },  // مغلق
      friday: { open: '00:00', close: '23:59', is24h: true },     // مفتوح 24 ساعة
      saturday: { open: '00:00', close: '23:15', is24h: false }   
    },
    get displayHours() {
      return this.open24h ? '24 ساعة' : this.workingHours[days[new Date().getDay()]].is24h ? '24 ساعة' :
        `${formatTime(this.workingHours[days[new Date().getDay()]].open)} - ${formatTime(this.workingHours[days[new Date().getDay()]].close)}`;
    }
  }
];

export const isBranchOpen = (branch) => {
  if (!branch) return false;

  const now = branch._currentTime || new Date();
  const currentDate = now.toISOString().split('T')[0]; // YYYY-MM-DD

  // التحقق من الإغلاق المؤقت
  if (branch.temporaryClosed?.isTemporaryClosed) {
    const tempHours = branch.temporaryClosed;
    
    // التحقق من التاريخ - إذا كان null سيطبق على اليوم الحالي
    if (tempHours.date && tempHours.date !== currentDate) {
      return isBranchOpenNormal(branch, now);
    }

    if (!tempHours.open || !tempHours.close) return false;

    // التحقق من أن الإغلاق المؤقت ضمن ساعات العمل العادية
    const dayName = days[now.getDay()];
    const regularHours = branch.workingHours[dayName];
    
    // تحويل جميع الأوقات إلى دقائق للمقارنة
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
    const tempOpenMinutes = parseInt(tempHours.open.split(':')[0]) * 60 + parseInt(tempHours.open.split(':')[1]);
    const tempCloseMinutes = parseInt(tempHours.close.split(':')[0]) * 60 + parseInt(tempHours.close.split(':')[1]);
    const regularOpenMinutes = parseInt(regularHours.open.split(':')[0]) * 60 + parseInt(regularHours.open.split(':')[1]);
    const regularCloseMinutes = parseInt(regularHours.close.split(':')[0]) * 60 + parseInt(regularHours.close.split(':')[1]);

    // التحقق من أن أوقات الإغلاق المؤقت ضمن ساعات العمل العادية
    let isWithinRegularHours = false;
    if (regularCloseMinutes > regularOpenMinutes) {
      // ساعات العمل العادية في نفس اليوم
      isWithinRegularHours = tempOpenMinutes >= regularOpenMinutes && tempCloseMinutes <= regularCloseMinutes;
    } else {
      // ساعات العمل تمتد لليوم التالي
      isWithinRegularHours = tempOpenMinutes >= regularOpenMinutes || tempCloseMinutes <= regularCloseMinutes;
    }

    if (!isWithinRegularHours) return false;

    // التحقق من أن الوقت الحالي في فترة الإغلاق المؤقت
    const isInTemporaryClosure = currentMinutes >= tempOpenMinutes && currentMinutes <= tempCloseMinutes;
    if (isInTemporaryClosure) return false;
  }

  return isBranchOpenNormal(branch, now);
};

// دالة مساعدة للتحقق من ساعات العمل العادية
const isBranchOpenNormal = (branch, now) => {
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const dayName = days[now.getDay()];

  if (branch.open24h) return true;

  const todayHours = branch.workingHours[dayName];
  if (todayHours.is24h) return true;

  if (todayHours.open === '00:00' && todayHours.close === '00:00' && !todayHours.is24h) {
    return false;
  }

  let currentTimeMinutes = hours * 60 + minutes;
  const openTimeMinutes = parseInt(todayHours.open.split(':')[0]) * 60 + parseInt(todayHours.open.split(':')[1]);
  let closeTimeMinutes = parseInt(todayHours.close.split(':')[0]) * 60 + parseInt(todayHours.close.split(':')[1]);

  if (closeTimeMinutes < openTimeMinutes) {
    if (currentTimeMinutes < closeTimeMinutes) {
      currentTimeMinutes += 24 * 60;
    }
    closeTimeMinutes += 24 * 60;
  }

  return currentTimeMinutes >= openTimeMinutes && currentTimeMinutes <= closeTimeMinutes;
};

// Hook لمراقبة حالة جميع الفروع
export const useBranchesStatus = () => {
  const [branchesStatus, setBranchesStatus] = useState({});
  const [remainingTimes, setRemainingTimes] = useState({});

  // تحديث حالة جميع الفروع
  const updateBranchesStatus = useCallback(() => {
    const now = new Date();
    const newStatus = {};
    const newRemainingTimes = {};
    
    restaurants.forEach(branch => {
      const branchWithTime = {
        ...branch,
        _currentTime: now
      };
      newStatus[branch.id] = isBranchOpen(branchWithTime);
      newRemainingTimes[branch.id] = getRemainingTime(branch);
    });
    
    setBranchesStatus(newStatus);
    setRemainingTimes(newRemainingTimes);
  }, []);

  useEffect(() => {
    // تحديث أولي
    updateBranchesStatus();

    // تحديث كل ثانية
    const interval = setInterval(() => {
      updateBranchesStatus();
    }, 1000);

    return () => clearInterval(interval);
  }, [updateBranchesStatus]);

  return { branchesStatus, remainingTimes };
};

// دالة لحساب الوقت المتبقي حتى الإغلاق
export const getRemainingTime = (branch) => {
  if (!branch) return null;
  
  const now = new Date();
  const currentDay = days[now.getDay()];
  const workingHours = branch.workingHours[currentDay];
  
  if (!workingHours || workingHours.is24h) return null;
  
  const [closeHours, closeMinutes] = workingHours.close.split(':').map(Number);
  const closeTime = new Date(now);
  closeTime.setHours(closeHours, closeMinutes, 0);
  
  // إذا كان وقت الإغلاق في اليوم التالي
  if (workingHours.open > workingHours.close && now.getHours() < closeHours) {
    closeTime.setDate(closeTime.getDate());
  } else if (workingHours.open > workingHours.close) {
    closeTime.setDate(closeTime.getDate() + 1);
  }
  
  const diffMs = closeTime - now;
  const diffMins = Math.floor(diffMs / 60000);
  const hours = Math.floor(diffMins / 60);
  const minutes = diffMins % 60;
  
  return { hours, minutes };
};

// دالة لتنسيق الوقت
export const formatTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  const hour = parseInt(hours);
  const ampm = hour >= 12 ? 'م' : 'ص';
  const formattedHour = hour % 12 || 12;
  return `${formattedHour}:${minutes.toString().padStart(2, '0')} ${ampm}`;
};

export const deliveryOptions = [
  {
    id: 1,
    component: TableNumber,
    name: 'إختيار الطاولة',
    img: '../../img/delivery/number.png',
    enabled: true,  // تفعيل/تعطيل خيار الطلب في المطعم
  },
  {
    id: 2,
    component: Transport,
    name: 'توصيل',
    img: '../../img/delivery/delivery.png',
    enabled: true,  // تفعيل/تعطيل خيار التوصيل
  },
  {
    id: 3,
    component: 'CarDelivery',
    name: 'تسليم للسيارة',
    img: '../../img/delivery/car.png',
    enabled: true,  // تفعيل/تعطيل خيار التسليم للسيارة
  },
  {
    id: 4,
    component: ReciveFromStore,
    name: 'إستلام',
    img: '../../img/delivery/pickup.png',
    enabled: true,  // تفعيل/تعطيل خيار الاستلام من الفرع
  },
]

// إعدادات عامة للتطبيق
export const appSettings = {
  // إعدادات التوصيل
  delivery: {
    minOrderAmount: 50, // الحد الأدنى للطلب للتوصيل
    deliveryFee: 10,   // رسوم التوصيل
    freeDeliveryAmount: 100, // مبلغ الطلب للتوصيل المجاني
  }
}