import { useEffect, useState, useCallback, useRef } from 'react'
import Modal from './Modal'
import { MdLocationOn, MdOutlinePlace } from 'react-icons/md'
import { AiOutlineUser } from 'react-icons/ai'
import { RiWhatsappFill } from 'react-icons/ri'
import { toast } from 'react-toastify'
import { IoIosArrowBack } from 'react-icons/io'
import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import { Link, useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../context'
import MyGoogleMap from './GoogleMap'
import { restaurants } from '../hooks/branchesManager'

const Transport = () => {
  const [formErrors, setFormErrors] = useState({})
  const [userLatLng, setUserLatLng] = useState(null)
  const [modalOn, setModalOn] = useState(false)
  const [fixedCenter, setFixedCenter] = useState()
  const [deliveryFee] = useState(10) // رسوم توصيل ثابتة
  const [nearestOpenBranch, setNearestOpenBranch] = useState(null)

  const navigate = useNavigate()
  const { cartData, setCartData, percentage } = useGlobalContext()
  const total = cartData.reduce((acc, item) => acc + item.price, 0).toLocaleString('en-US')

  // تنسيق الوقت من 24 ساعة إلى 12 ساعة مع ص/م
  const formatTime = (time) => {
    if (!time) return ''
    const [hours, minutes] = time.split(':')
    const hour = parseInt(hours)
    const ampm = hour >= 12 ? 'م' : 'ص'
    const formattedHour = hour % 12 || 12
    return `${formattedHour}:${minutes} ${ampm}`
  }

  // حساب المسافة بين نقطتين باستخدام معادلة هافرساين
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371 // نصف قطر الأرض بالكيلومتر
    const dLat = (lat2 - lat1) * Math.PI / 180
    const dLon = (lon2 - lon1) * Math.PI / 180
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
    return R * c
  }

  // التحقق من أن الفرع مفتوح
  const isBranchOpen = useCallback((branch) => {
    if (branch.open24h) return true

    const now = new Date()
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    const currentDay = days[now.getDay()]
    const currentTime = now.toLocaleTimeString('en-US', { 
      hour12: false, 
      hour: '2-digit', 
      minute: '2-digit'
    })

    const workingHours = branch.workingHours[currentDay]
    if (!workingHours) return false

    if (workingHours.open > workingHours.close) {
      return currentTime >= workingHours.open || currentTime <= workingHours.close
    }
    return currentTime >= workingHours.open && currentTime <= workingHours.close
  }, [])

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        if (location) {
          const { latitude, longitude } = location.coords
          const userLocation = { lat: latitude, lng: longitude }
          setUserLatLng(userLocation)
          
          // العثور على أقرب فرع مفتوح
          const findNearestOpenBranch = (location) => {
            if (!location) return null

            const sortedBranches = restaurants
              .map(branch => ({
                ...branch,
                distance: calculateDistance(
                  location.lat,
                  location.lng,
                  branch.coords.lat,
                  branch.coords.lng
                ),
                isOpen: isBranchOpen(branch)
              }))
              .sort((a, b) => a.distance - b.distance)

            const nearestOpen = sortedBranches.find(branch => 
              branch.isOpen && branch.services.includes('delivery')
            )

            return nearestOpen
          }

          const nearestBranch = findNearestOpenBranch(userLocation)
          setNearestOpenBranch(nearestBranch)
        } else {
          toast.info('قم بتفعيل إذن الموقع ، انتقل إلى الإعدادات > الخصوصية > خدمات الموقع > مواقع سفاري واسمح بالوصول للموقع .')
        }
      }, (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          toast.info('قم بتفعيل إذن الموقع ، انتقل إلى الإعدادات > الخصوصية > خدمات الموقع > مواقع سفاري واسمح بالوصول للموقع .')
        } else if (error.code === error.POSITION_UNAVAILABLE) {
          toast.error('الموقع غير متاح. يرجى التأكد من تشغيل خدمات الموقع.')
        } else if (error.code === error.TIMEOUT) {
          toast.error('انتهت مهلة تحديد الموقع. يرجى المحاولة مرة أخرى.')
        }
      })
    } else {
      toast.error('متصفحك لا يدعم خدمة تحديد المواقع')
    }
  }, [isBranchOpen])

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const values = Object.fromEntries(formData)
    
    // إعادة تعيين الأخطاء
    setFormErrors({})
    
    // التحقق من كل حقل
    const errors = {}
    if (!values.name) errors.name = true
    if (!values.phone) errors.phone = true
    if (!values.address) errors.address = true

    // إذا كان هناك أخطاء
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      
      // التركيز على أول حقل فارغ
      if (errors.name) {
        nameRef.current?.focus()
      } else if (errors.phone) {
        phoneRef.current?.focus()
      } else if (errors.address) {
        addressRef.current?.focus()
      }

      if (!toast.isActive('form-error')) {
        toast.error('الرجاء تعبئة جميع الحقول المطلوبة', {
          toastId: 'form-error'
        })
      }
      return
    }

    // التحقق من وجود فرع مفتوح
    if (!nearestOpenBranch) {
      const days = {
        sunday: 'الأحد',
        monday: 'الإثنين',
        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس',
        friday: 'الجمعة',
        saturday: 'السبت'
      }

      // البحث عن أقرب فرع بغض النظر عن حالته
      const findNearestBranch = (location) => {
        if (!location) return null

        return restaurants
          .filter(branch => branch.services.includes('delivery'))
          .map(branch => ({
            ...branch,
            distance: calculateDistance(
              location.lat,
              location.lng,
              branch.coords.lat,
              branch.coords.lng
            )
          }))
          .sort((a, b) => a.distance - b.distance)[0]
      }

      const nearestBranch = findNearestBranch(userLatLng || fixedCenter)
      
      if (!nearestBranch) {
        toast.error('عذراً، لا يوجد فروع متاحة للتوصيل في منطقتك حالياً')
        return
      }

      const today = new Date().getDay()
      const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
      const todayName = dayNames[today]
      const workingHours = nearestBranch.workingHours[todayName]
      
      toast.error(
        `عذراً، خدمة التوصيل غير متاحة حالياً ⏰\n\n` +
        `أوقات العمل اليوم (${days[todayName]}):\n` +
        `من ${formatTime(workingHours.open)} إلى ${formatTime(workingHours.close)}\n\n` +
        `الفرع: ${nearestBranch.name}\n` +
        `المسافة: ${nearestBranch.distance.toFixed(1)} كم`,
        {
          autoClose: 10000,
          style: { 
            whiteSpace: 'pre-line', 
            textAlign: 'right',
            direction: 'rtl'
          }
        }
      )
      return
    }

    setModalOn(true)
    const date = new Date(Date.now())
    let hours = date.getHours()
    let minutes = date.getMinutes()
    const ampm = hours >= 12 ? 'م' : 'ص'
    hours = hours % 12 || 12
    minutes = minutes < 10 ? '0' + minutes : minutes
    const time = `${hours}:${minutes} ${ampm}`

    const userLocation = fixedCenter || userLatLng
    const orderTotalWithDelivery = deliveryFee + cartData.reduce((acc, item) => acc + item.price, 0)
    const discount = ((percentage / 100) * orderTotalWithDelivery).toLocaleString('en-US')
    const totalAfterDiscount = (orderTotalWithDelivery - (percentage / 100) * orderTotalWithDelivery).toLocaleString('en-US')

    // حفظ عنوان التوصيل
    localStorage.setItem('deliveryAddress', values.address)

    // حفظ الطلب في localStorage
    const orders = JSON.parse(localStorage.getItem('orders') || '[]')
    const lastOrderNumber = orders.length > 0 ? parseInt(orders[0].orderId) || 0 : 0
    const newOrderNumber = lastOrderNumber + 1

    const newOrder = {
      orderId: newOrderNumber,
      date: new Date().toISOString(),
      items: cartData.map(item => ({
        name: item.name,
        price: item.price,
        quantity: item.quantity || 1,
        image: item.img,
        size: item.size?.name || 'وسط',
        additions: item.extra?.map(e => e.name) || [],
        modifications: item.edits || []
      })),
      deliveryMethod: 'توصيل',
      deliveryDetails: {
        address: values.address,
        location: userLocation,
        distance: '0.00', 
        deliveryFee: deliveryFee 
      },
      status: 'مكتمل',
      total: orderTotalWithDelivery
    };

    console.log('Transport.js - Created new order:', newOrder);
    orders.unshift(newOrder);
    localStorage.setItem('orders', JSON.stringify(orders));

    // إنشاء رسالة WhatsApp
    const message = `%0A%2Aنوع الطلب%2A %3A توصيل %0A%2Aرقم الطلب%2A %3A 0000${newOrderNumber} %0A%2Aاسم العميل%2A %3A ${values.name} %0A%2Aرقم العميل%2A %3A ${values.phone} %0A%2Aعنوان العميل%2A %3A ${values.address} %0A%2Aموقع العميل%2A %3A https://maps.google.com/?q=${userLocation.lat},${userLocation.lng} %0A---------------------------%0A${cartData
      .map((item) => {
        let itemMessage = `%2Aالصنف%2A %3A ${item.name} %0A`

        if (item.size) {
          let size = ''
          switch (item.size.name) {
            case 'small':
              size = 'صغير'
              break
            case 'medium':
              size = 'وسط'
              break
            case 'large':
              size = 'كبير'
              break
            default:
              size = ''
          }
          if (size) {
            itemMessage += `%2Aالحجم%2A %3A ${size} %0A`
          }
        }

        itemMessage += `%2Aالكمية%2A %3A ${item.quantity} %0A%2Aالسعر%2A %3A ${item.price} ريال`

        if (item.extra && item.extra.length > 0) {
          itemMessage += `%0A%2Aالإضافات%2A %3A %0A${item.extra
            .map((e) => `• ${e.name}`)
            .join('%0A')}`
        }
        
        if (item.edits && item.edits.length > 0) {
          itemMessage += `%0A%2Aالتعديلات%2A %3A %0A${item.edits
            .map((e) => `• ${e}`)
            .join('%0A')}`
        }

        return itemMessage
      })
      .join('%0A---------------------------%0A')
    }%0A---------------------------%0A%0A*مبلغ الطلب* %3A ${total} ريال %0A*المجموع* %3A ${orderTotalWithDelivery.toLocaleString('en-US')} ريال %0A${
      percentage
        ? `*الخصم* %3A ${discount} ريال %0A*المجموع بعد الخصم* %3A ${totalAfterDiscount} ريال %0A*`
        : ''
    }*وقت الطلب* %3A ${time}`

    const deliveryType = 'deliver_home';
    localStorage.setItem('deliveryType', deliveryType);

    if (userLatLng.lat === 0) {
      toast.info('قم بتفعيل إذن الموقع ، انتقل إلى الإعدادات > الخصوصية > خدمات الموقع > مواقع سفاري واسمح بالوصول للموقع .')
    } else {
      window.open(
        `https://api.whatsapp.com/send?phone=+966500919252&text=${message}`
      )
      toast.success('تم تأكيد الطلب بنجاح')
      navigate('/')
      setCartData([])
    }
  }

  // التحقق من الحقل عند التغيير
  const validateField = (name, value) => {
    if (!value) {
      setFormErrors(prev => ({ ...prev, [name]: true }))
    } else {
      setFormErrors(prev => {
        const newErrors = { ...prev }
        delete newErrors[name]
        return newErrors
      })
    }
  }

  // معالجة تغيير الحقول
  const handleInputChange = (e) => {
    const { name, value } = e.target
    validateField(name, value)
  }

  // التحكم في موضع المؤشر
  const handlePhoneFocus = (e) => {
    setTimeout(() => {
      if (phoneRef.current) {
        const length = phoneRef.current.value.length;
        phoneRef.current.setSelectionRange(length, length);
      }
    }, 0);
  };

  // إضافة مراجع للحقول
  const nameRef = useRef(null)
  const phoneRef = useRef(null)
  const addressRef = useRef(null)

  return (
    <div className='absolute w-full h-full'>
      <div className='w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className=' w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className=' w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1 text-brown-400'>
                عنوان التوصيل
              </span>
              <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                {' '}
                Delivery Address
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 ml-4 rounded-full text-main-900 hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
          title='رجوع'
          onClick={() => navigate(-1)}
        />
      </div>

      <div className='relative max-w-md h-[calc(100vh-150px)] pt-[64px] md:h-[calc(100vh-60px)] w-full overflow-hidden flex flex-col'>
        <MyGoogleMap
          latlng={userLatLng}
          fixedCenter={fixedCenter}
          setFixedCenter={setFixedCenter}
        />
        <form onSubmit={handleSubmit} className='fixed max-w-md bottom-0 w-full dark:bg-gray-700 flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems'>
          <div className='space-y-3'>
            <div className='flex flex-col text-sm'>
              <label htmlFor='name' className='relative'>
                <input
                  type='text'
                  id='name'
                  name='name'
                  autoComplete='off'
                  maxLength='25'
                  enterKeyHint="next"
                  ref={nameRef}
                  className={`peer w-full p-3 pr-10 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 placeholder:text-gray-400 placeholder:opacity-0 focus:placeholder:opacity-100 ${
                    formErrors?.name ? 'border-red-500 text-red-500 animate-shake' : 'border-gray-300 focus:border-main-900 text-gray-900'
                  }`}
                  placeholder='مثال : محمد'
                  onChange={handleInputChange}
                />
                <AiOutlineUser className={`absolute right-3 top-1/2 -translate-y-1/2 text-lg ${
                  formErrors?.name ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                }`} />
                <span className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 right-10 peer-placeholder-shown:bg-transparent peer-focus:bg-white ${
                  formErrors?.name ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                }`}>
                  اسم العميل
                </span>
              </label>
            </div>

            <div className='flex flex-col text-sm'>
              <label htmlFor='phone' className='relative'>
                <input
                  type='tel'
                  id='phone'
                  name='phone'
                  autoComplete='off'
                  inputMode="numeric"
                  maxLength="10"
                  ref={phoneRef}
                  dir="rtl"
                  style={{ textAlign: 'right', direction: 'rtl' }}
                  onChange={handleInputChange}
                  onFocus={handlePhoneFocus}
                  className={`peer w-full p-3 pr-10 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 placeholder:text-gray-400 placeholder:opacity-0 focus:placeholder:opacity-100 ${
                    formErrors?.phone ? 'border-red-500 text-red-500 animate-shake' : 'border-gray-300 focus:border-main-900 text-gray-900'
                  }`}
                  placeholder='مثال : 0555555555'
                />
                <RiWhatsappFill className={`absolute right-3 top-1/2 -translate-y-1/2 text-lg ${
                  formErrors?.phone ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                }`} />
                <span className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 right-10 peer-placeholder-shown:bg-transparent peer-focus:bg-white ${
                  formErrors?.phone ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                }`}>
                  رقم التواصل
                </span>
              </label>
            </div>

            <div className='flex flex-col text-sm'>
              <label htmlFor='address' className='relative'>
                <input
                  type='text'
                  id='address'
                  name='address'
                  autoComplete='off'
                  maxLength='25'
                  enterKeyHint="send"
                  ref={addressRef}
                  className={`peer w-full p-3 pr-10 border-2 rounded-xl bg-transparent outline-none transition-all duration-300 placeholder:text-gray-400 placeholder:opacity-0 focus:placeholder:opacity-100 ${
                    formErrors?.address ? 'border-red-500 text-red-500 animate-shake' : 'border-gray-300 focus:border-main-900 text-gray-900'
                  }`}
                  placeholder='مثال : حي الملقا'
                  onChange={handleInputChange}
                />
                <MdOutlinePlace className={`absolute right-3 top-1/2 -translate-y-1/2 text-lg ${
                  formErrors?.address ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                }`} />
                <span className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 right-10 peer-placeholder-shown:bg-transparent peer-focus:bg-white ${
                  formErrors?.address ? 'text-red-500' : 'text-gray-400 peer-focus:text-main-900'
                }`}>
                  العنوان
                </span>
              </label>
            </div>

            <button
              type='submit'
              className='bg-main-900 text-white py-2 rounded-full flex-1 w-full mt-4'
            >
              تأكيد الطلب
            </button>
          </div>
        </form>
        {modalOn ? (
          <Modal setModalOn={setModalOn} title='عفواً'>
            <div className='flex items-center justify-center text-center flex-col gap-4'>
              <MdLocationOn className='text-5xl text-main-900' />
              <p>عفواً، لا نستطيع التوصيل لأي موقع أبعد من 100 كيلومترات</p>
              <button onClick={() => setModalOn(false)}>إغلاق</button>
            </div>
          </Modal>
        ) : null}
      </div>
    </div>
  )
}
export default Transport
