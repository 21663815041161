import { IoLogoInstagram, IoLogoWhatsapp, IoCallOutline } from 'react-icons/io5';
import { BsSnapchat } from "react-icons/bs";


export const sideNavData = [
  {
    id: 1,
    text: 'سناب شات',
    icon: BsSnapchat,
    href: 'https://www.snapchat.com/add/hyam.coffe',
  },
  {
    id: 2,
    text: 'انستجرام',
    icon: IoLogoInstagram,
    href: 'https://instagram.com/hyam.coffe',
  },
  {
    id: 3,
    text: 'واتساب',
    icon: IoLogoWhatsapp,
    href: 'https://api.whatsapp.com/send/?phone=966538081433',
  },
  {
    id: 4,
    text: 'أتصل بنا',
    icon: IoCallOutline,
    href: 'tel:0538081433',
  },
]

export const data = [
  {
    id: 1,
    title: 'القهوة الساخنة',
    image: '../../../img/list/coffe-cup.png',
    selected: true,
    items: [
        {
            id: 1,
            title: 'v60',
            description: '',
            image: '../../img/food/8hoh-alyom.jpg',
            price: 12,
            calories: 5,
            selected: false,
            available: true,
        },
        {
            id: 2,
            title: 'قهوة اليوم',
            description: '',
            image: '../../img/food/could-pro_8_11zon.jpg',
            price: 7,
            calories: 25,
            selected: false,
            available: true,
        },
        {
            id: 3,
            title: 'اسبريسو',
            description: '',
            image: '../../img/food/esprso_10_11zon.jpg',
            price: "6-8",
            calories: 20,
            selected: false,
            available: true,
        },
        {
            id: 4,
            title: 'امريكانو',
            description: '',
            image: '../../img/food/amricano_1_11zon.jpg',
            price: "8-12",
            calories: 225,
            selected: false,
            available: true,
        },
        {
            id: 5,
            title: 'لاتيه',
            description: '',
            image: '../../img/food/latespanish_20_11zon.jpg',
            price: "8-12",
            calories: 148,
            selected: false,
            available: true,
        },
        {
            id: 6,
            title: 'سبانش لاتيه',
            description: '',
            image: '../../img/food/latespanish_20_11zon.jpg',
            price: "8-12",
            calories: 360,
            selected: false,
            available: true,
        },
        {
            id: 7,
            title: 'كابتشينو',
            description: '',
            image: '../../img/food/latespanish_20_11zon.jpg',
            price: "7-12",
            calories: 300,
            selected: false,
            available: true,
        },
        {
            id: 8,
            title: 'ميكاتو',
            description: '',
            image: '../../img/food/maciato-cortado-falt_22_11zon.jpg',
            price: "7-9",
            calories: 244,
            selected: false,
            available: true,
        },
        {
            id: 9,
            title: 'كورتادو',
            description: '',
            image: '../../img/food/maciato-cortado-falt_22_11zon.jpg',
            price: "8-10",
            calories: 15,
            selected: false,
            available: true,
        },
        {
            id: 10,
            title: 'فلات وايت',
            description: '',
            image: '../../img/food/falt_22_11zon.jpg',
            price: "10-12",
            calories: 110,
            selected: false,
            available: true,
        },
        {
            id: 11,
            title: 'قهوة فرنسية',
            description: '',
            image: '../../img/food/frinsh.jpg',
            price: "8-12",
            calories: 92,
            selected: false,
            available: true,
        },
        {
            id: 12,
            title: 'هوت شوكليت دبل اسبريسو',
            description: '',
            image: '../../img/food/hot-chocolate.jpg',
            price: "10-14",
            calories: 170,
            selected: false,
            available: true,
        },
        {
            id: 13,
            title: 'هوت شوكليت',
            description: '',
            image: '../../img/food/hot-chocolate.jpg',
            price: "8-10",
            calories: 158,
            selected: false,
            available: true,
        },
        {
            id: 14,
            title: 'تركي سادة',
            description: '',
            image: '../../img/food/turkey.png',
            price: 8,
            calories: 20,
            selected: false,
            available: true,
        },
        {
            id: 15,
            title: 'تركي بالحليب',
            description: '',
            image: '../../img/food/turkey.png',
            price: 10,
            calories: 36,
            selected: false,
            available: true,
        },
        {
            id: 16,
            title: 'قهوة سعودية',
            description: '',
            image: '../../img/items.png',
            price: 6,
            calories: 5,
            selected: false,
            available: true,
        },
    ]
},
{
    id: 2,
    title: 'القهوة الباردة',
    image: '../../../img/list/bard.png',
    selected: false,
    available: true,
    items: [
        {
            id: 1,
            title: 'v60',
            description: '',
            image: '../../img/food/8hoh-alyom.jpg',
            price: 12,
            calories: 5,
            selected: false,
            available: true,
        },
        {
            id: 2,
            title: 'قهوة اليوم',
            description: 'صغير - كبير',
            image: '../../img/food/8hoh-alyom.jpg',
            price: "7-10",
            calories: 25,
            selected: false,
            available: true,
        },
        {
            id: 3,
            title: 'كولد برو',
            description: '',
            image: '../../img/food/could-pro_8_11zon.jpg',
            price: 16,
            calories: 25,
            selected: false,
            available: true,
        },
        {
            id: 4,
            title: 'ايس لاتيه',
            description: '',
            image: '../../img/food/ice-late-spanishice_13_11zon.jpg',
            price: 10,
            calories: 206,
            selected: false,
            available: true,
        },
        {
            id: 5,
            title: 'ايس سبانش لاتيه',
            description: '',
            image: '../../img/food/ice-late-spanishice_13_11zon.jpg',
            price: 10,
            calories: 155,
            selected: false,
            available: true,
        },
        {
            id: 6,
            title: 'ايس بستاشيو لاتيه',
            description: '',
            image: '../../img/food/ice-pistashio_14_11zon.jpg',
            price: 12,
            calories: 360,
            selected: false,
            available: true,
        },
        {
            id: 7,
            title: 'ايس وايت موكا',
            description: '',
            image: '../../img/food/ice-wait-moca_16_11zon.jpg',
            price: 12,
            calories: 323,
            selected: false,
            available: true,
        },
        {
            id: 8,
            title: 'ماتشا',
            description: '',
            image: '../../img/food/matsha.jpg',
            price: 12,
            calories: 213,
            selected: false,
            available: true,
        },
        {
            id: 9,
            title: 'ايس كراميل ميكاتو',
            description: '',
            image: '../../img/food/ice-wait-moca_16_11zon.jpg',
            price: 12,
            calories: 240,
            selected: false,
            available: true,
        },

    ]
},
{
    id: 3,
    title: 'الحـلا',
    image: '../../../img/list/soit.png',
    selected: false,
    available: true,
    items: [
        {
            id: 1,
            title: 'مقلوبة هيام',
            description: '',
            image: '../../img/food/m8lobh.jpg',
            price: 18,
            calories: 450,
            selected: false,
            available: true,
        },
        {
            id: 2,
            title: 'فرنش توست',
            description: '',
            image: '../../img/food/frinsh-tost.jpg',
            price: 17,
            calories: 380,
            selected: false,
            available: true,
        },
        {
            id: 3,
            title: 'حلا الرمان',
            description: '',
            image: '../../img/food/roman.jpg',
            price: 10,
            calories: 320,
            selected: false,
            available: true,
        },
        {
            id: 4,
            title: 'ترفل مانجو',
            description: '',
            image: '../../img/food/trfl-mango.jpg',
            price: 18,
            calories: 290,
            selected: false,
            available: true,
        },
        {
            id: 5,
            title: 'كيك النوتيلا',
            description: '',
            image: '../../img/food/notiela.jpg',
            price: 19,
            calories: 480,
            selected: false,
            available: true,
        },
        {
            id: 6,
            title: 'كيكة الزعفران',
            description: '',
            image: '../../img/food/zafran-kik.jpg',
            price: 17,
            calories: 350,
            selected: false,
            available: true,
        },
        {
            id: 7,
            title: 'كيكة الحليب',
            description: '',
            image: '../../img/food/milk-kik.jpg',
            price: 17,
            calories: 360,
            selected: false,
            available: true,
        },
        {
            id: 8,
            title: 'كريب',
            description: '',
            image: '../../img/food/krip.jpg',
            price: 10,
            calories: 300,
            selected: false,
            available: true,
        },
        {
            id: 9,
            title: 'وافل',
            description: '',
            image: '../../img/food/wafel.jpg',
            price: 10,
            calories: 315,
            selected: false,
            available: true,
        },
        {
            id: 10,
            title: 'ميني بان كيك',
            description: '',
            image: '../../img/food/minebankik.jpg',
            price: "10-15",
            calories: 280,
            selected: false,
            available: true,
        },
        {
            id: 11,
            title: 'سان سيبستيان',
            description: '',
            image: '../../img/food/san.jpg',
            price: 18,
            calories: 420,
            selected: false,
            available: true,
        }
    ]
},
{
    id: 4,
    title: 'مشروبات هيام الخاصة',
    image: '../../../img/list/moheto5a9.png',
    selected: false,
    available: true,
    items: [
        {
            id: 1,
            title: 'سجنتشر',
            description: '',
            image: '../../img/food/sgntshr.jpg',
            price: "14-16",
            calories: 170,
            selected: false,
            available: true,
        },
        {
            id: 2,
            title: 'موهيتو هيام',
            description: 'سفن أب',
            image: '../../img/food/mohito-m5lo6_28_11zon.jpg',
            price: "10-14",
            calories: 165,
            selected: false,
            available: true,
        },
        {
            id: 3,
            title: 'مخلوط هيام ',
            description: 'كودرد',
            image: '../../img/food/mohito-m5lo6_28_11zon.jpg',
            price: "10-14",
            calories: 250,
            selected: false,
            available: true,
        },
    ]
},
{
    id: 5,
    title: 'المشروبات المثلجة',
    image: '../../../img/list/iced-coffee.png',
    selected: false,
    available: true,
    items: [
        {
            id: 1,
            title: 'ميلك شيك موكا',
            description: '',
            image: '../../img/food/milk-chek-moca_23_11zon.jpg',
            price: 12,
            calories: 250,
            selected: false,
            available: true,
        },
        {
            id: 2,
            title: 'ميلك شيك شوكليت',
            description: '',
            image: '../../img/food/milk-chek-moca_23_11zon.jpg',
            price: 12,
            calories: 290,
            selected: false,
            available: true,
        },
        {
            id: 3,
            title: 'ميلك شيك سنيكرز',
            description: '',
            image: '../../img/food/milk-chek-snicres_25_11zon.jpg',
            price: 14,
            calories: 270,
            selected: false,
            available: true,
        },
        {
            id: 4,
            title: 'ملك شيك اوريو',
            description: '',
            image: '../../img/food/milk-chek-orio_24_11zon.jpg',
            price: 14,
            calories: 110,
            selected: false,
            available: true,
        },
    ]
},
{
    id: 6,
    title: 'المشروبات الباردة',
    image: '../../../img/list/drink.png',
    selected: false,
    available: true,
    items: [
        {
            id: 1,
            title: 'كودرد نكهه',
            description: 'متوفر جميع النكهات',
            image: '../../img/food/kodred-mohito-nkhah_18_11zon.jpg',
            price: "8-12",
            calories: 210,
            selected: false,
            available: true,
        },
        {
            id: 2,
            title: 'موهيتو نكهه',
            description: 'متوفر جميع النكهات',
            image: '../../img/food/kodred-mohito-nkhah_18_11zon.jpg',
            price: "8-12",
            calories: 133,
            selected: false,
            available: true,
        },
        {
            id: 3,
            title: 'ليمون نعناع',
            description: '',
            image: '../../img/food/lemoon_21_11zon.jpg',
            price: "7-10",
            calories: 40,
            selected: false,
            available: true,
        },
        {
            id: 4,
            title: 'مياة معدنية',
            description: '',
            image: '../../img/items.png',
            price: 1,
            calories: 0,
            selected: false,
            available: true,
        },
    ]
},
{
    id: 7,
    title: 'البوكسات',
    image: '../../../img/list/box.png',
    selected: false,
    available: true,
    items: [
        {
            id: 1,
            title: 'دلة هيام',
            description: 'تعادل 5 أكواب',
            image: '../../img/food/8hoh.jpg',
            price: 28,
            calories: 25,
            selected: false,
            available: true,
        },
        {
            id: 2,
            title: 'بوكس القهوة',
            description: 'حجم كبير عدد 12 علبة',
            image: '../../img/food/coffee-box_7_11zon.jpg',
            price: 140,
            calories: 60,
            selected: false,
            available: true,
        },
        {
            id: 3,
            title: 'بوكس القهوة',
            description: 'حجم صغير عدد 8 علبة',
            image: '../../img/food/coffee-box_7_11zon.jpg',
            price: 95,
            calories: 40,
            selected: false,
            available: true,
        },
        {
            id: 4,
            title: 'بوكس موهيتو',
            description: 'حجم كبير عدد 12 علبة',
            image: '../../img/food/mohito-box_27_11zon.jpg',
            price: 130,
            calories: 1596,
            selected: false,
            available: true,
        },
        {
            id: 5,
            title: 'بوكس موهيتو',
            description: 'حجم صغير عدد 6 علبة',
            image: '../../img/food/mohito-box_27_11zon.jpg',
            price: 70,
            calories: 798,
            selected: false,
            available: true,
        },
    ]
},
{
    id: 8,
    title: 'المأكولات',
    image: '../../../img/list/croissant.png',
    selected: false,
    available: true,
    items: [
        {
            id: 1,
            title: 'كروسان جبنة',
            description: '',
            image: '../../img/food/croisant.jpg',
            price: 7,
            calories: 240,
            selected: false,
            available: true,
        },
        {
            id: 2,
            title: 'دونات شوكليت',
            description: '',
            image: '../../img/food/donut.png',
            price: 6,
            calories: 307,
            selected: false,
            available: true,
        },
        {
            id: 3,
            title: 'كوكيز',
            description: '',
            image: '../../img/food/cocies.jpg',
            price: 10,
            calories: 140,
            selected: false,
            available: true,
        },
    ]
},
]